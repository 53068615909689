import React from 'react';
import he from 'he';
import { SightingMedia } from './sighting_media';
import { SightingMap } from './sighting_map';
import { FieldControl, validateField } from './_field_control';
import { ImageDropzone } from './image-dropzone';
import { PhotoThumbnails } from './photo_thumbnails';

import history from '../../utils/history';
import { rootpath } from '../../utils/rootpath';

import ReactTooltip from 'react-tooltip';

import { toast } from 'react-toastify';

function set_position() {
  return new Promise(function(resolve, reject) {
    navigator.geolocation.getCurrentPosition(resolve);
  });
}

export class SightingEditPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sighting: null
    };
    this._fetch = this._fetch.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
  }

  _fetch = async () => {
    if (window._do_pwf_debug_log) console.log('FETCH');
    const _schema = this.props.networkfn(process.env.REACT_APP_API_SERVER + '/schema', {});
    const _rsp = this.props.sighting_id
      ? this.props.networkfn(process.env.REACT_APP_API_SERVER + '/sightings/id/' + this.props.sighting_id, {})
      : null;
    // Await later so that tasks can complete in parallel.
    const rsp = this.props.sighting_id ? await _rsp : { ok: true, returned_body: {} };
    const schema = await _schema;

    if (!this.props.sighting_id) {
      // Build in some reasonable defaults
      const position = await set_position();
      rsp.returned_body.latitude = position.coords.latitude;
      rsp.returned_body.longitude = position.coords.longitude;
      rsp.returned_body.sighting_date_and_time_utc = new Date();
    }

    if (rsp.ok) {
      const returned_sighting = rsp.returned_body;
      const returned_schema = schema.returned_body.schema;

      for (var schema_field in returned_schema.fields) {
        let field = returned_schema.fields[schema_field];
        let value = returned_sighting[field.field_name];
        if (field.type === 'number') {
          // Special case handling for fields of type number...
          if (typeof value === 'string' && value !== '') {
            // Do not convert empty strings to 0
            const _as_num = Number(value);
            if (!isNaN(_as_num)) {
              if (window._do_pwf_debug_log) console.log('Converting to number: ' + _as_num);
              value = _as_num;
              returned_sighting[field.field_name] = value;
            }
          }
        }
      }

      returned_sighting.schema_version = parseInt( schema.returned_body.version );

      this.setState({
        sighting: returned_sighting,
        schema: returned_schema,
        photos: returned_sighting.sighting_photos
      });
    } else {
      this.setState({ sighting: null, schema: null, error: rsp });
    }
  };

  handleSubmitClick = async function(event) {
    event.preventDefault();

    if (window._do_pwf_debug_log) console.log('click: ' + JSON.stringify(this.state.sighting, null, 2));

    const opts = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.state.sighting),
      credentials: 'include'
    };

    const rsp = this.props.sighting_id
      ? await this.props.networkfn(process.env.REACT_APP_API_SERVER + '/sightings/id/' + this.props.sighting_id, opts)
      : await this.props.networkfn(process.env.REACT_APP_API_SERVER + '/sightings/create', opts);

    if (rsp.ok) {
      if (window._do_pwf_debug_log) console.log('Success');
      if (window._do_pwf_debug_log) console.log(rsp);

      if (this.props.sighting_id && this.props.on_save_success) {
        history.push({
          pathname: rootpath + this.props.on_save_success
        });
      } else if (this.props.sighting_id) {
        this._fetch();
      } else {
        // This must have been a creation screen
        if (window._do_pwf_debug_log) console.log('NEW SIGHTING ID: ');
        if (window._do_pwf_debug_log) console.log(rsp.returned_body.sighting.sighting_id);
        history.push({
          pathname: rootpath + '/view_sighting/' + rsp.returned_body.sighting.sighting_id
        });
      }
    } else {
      if (rsp.returned_body && rsp.returned_body.status === 'error' && rsp.returned_body.errors) {
        this.setState({ errors: rsp.returned_body.errors });
      } else {
        const toast_error = rsp.returned_body.message ? rsp.returned_body.message : rsp.status_text;
        toast.error('Unknown error: ' + toast_error);
      }
    }
  };

  componentDidMount() {
    this._fetch();
  }

  componentDidUpdate() {
    if (this.state.sighting && this.state.schema) {
      // Cache the schema definitions
      this.schema_display_fields = {};

      for (var schema_field in this.state.schema.fields) {
        let field = this.state.schema.fields[schema_field];
        this.schema_display_fields[field.field_name] = field;
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    // or componentDidUpdate
    this._fetch();
  }

  handleInputChange(event) {
    const target = event.dataset && event.dataset.isDateTimePicker ? event : event.target; // If the "event" has this, it's the datepicker

    const is_multi_select = target.dataset.isMultiSelect;
    const field_name = is_multi_select ? target.dataset.multiSelectField : target.id;

    let input_value = target.value;

    if (target.type === 'checkbox') {
      input_value = target.checked;
    } else if (target.dataset.isDateTimePicker) {
      try {
        input_value = new Date(target.value).toISOString();
      } catch (e) {
        input_value = null;
      }
    } else if (target.dataset.isNumberField) {
      // Special case handling for fields of type number...
      if (typeof input_value === 'string' && input_value !== '') {
        // Do not convert empty strings to 0
        const _as_num = Number(input_value);
        if (!isNaN(_as_num)) {
          if (window._do_pwf_debug_log) console.log('Converting to number: ' + _as_num);
          input_value = _as_num;
        }
      }
    }

    this.setState(state => {
      const sighting = state.sighting;

      const data_object = { ...sighting };

      if (is_multi_select) {
        let current_values = data_object[field_name] || [];
        const multi_select_key = target.dataset.multiSelectKey;

        if (!!input_value) {
          // add key to values
          current_values.push(multi_select_key);
        } else {
          // remove key from values
          // eslint-disable-next-line
          current_values = current_values.filter(key => key != multi_select_key);
        }

        data_object[field_name] = current_values;
      } else {
        data_object[field_name] = input_value;
      }

      return { sighting: data_object };
    });
  }

  handleBlur(event) {
    //const target = event.target;
  }

  render() {
    if (!this.state.sighting || !this.state.schema) return null;

    const schema = this.state.schema;

    const loc_str = this.state.sighting.latitude + ', ' + this.state.sighting.longitude;

    if (!this.schema_display_fields) {
      // Cache the schema definitions
      this.schema_display_fields = {};

      for (let schema_field in this.state.schema.fields) {
        let field = this.state.schema.fields[schema_field];
        this.schema_display_fields[field.field_name] = field;
      }
    }

    const species_schema_definition = this.schema_display_fields ? this.schema_display_fields.species_code : null;

    if (!species_schema_definition) return null;

    const species_entry = species_schema_definition.allowed_values.find(av => av.key === this.state.sighting.species_code);

    let submit_enabled = true;

    return (
      <section className='section'>
        <div className='columns is-centered'>
          <div className='column is-half'>
            <div className='card box' key={this.state.sighting.sighting_id}>
              <ReactTooltip />

              <SightingMedia
                species_entry={species_entry}
                sighting_date_and_time_utc={this.state.sighting.sighting_date_and_time_utc}
              />

              <div className='content'>
                {schema.fields.map(field => {
                  const value = this.state.sighting[field.field_name];
                  let _val = value;
                  if (typeof value === 'string') {
                    _val = he.decode(value);
                  } else if (Array.isArray(value)) {
                    _val = value.map(elt => he.decode(elt));
                  }

                  const valid = !validateField(field, _val);

                  submit_enabled = submit_enabled && valid;

                  return (
                    <div key={field.field_name} style={{ paddingTop: '2rem' }}>
                      <p className='title is-size-5'>
                        {field.label}{' '}
                        {field.required ? (
                          <span data-tip='Required Field' className='has-text-danger'>
                            *
                          </span>
                        ) : null}
                      </p>
                      <p>
                        {field.description ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: field.description
                            }}
                          />
                        ) : (
                          <span />
                        )}
                      </p>
                      <div className='field'>
                        {
                          <FieldControl
                            schema_field={field}
                            field_contents={_val}
                            change_handler={this.handleInputChange}
                            blur_handler={this.handleBlur}
                            error_message={this.state.errors ? this.state.errors[field.field_name] : null}
                          />
                        }
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className='content'>
                <div className='content has-text-centered'>
                  <button className='button is-large is-link' disabled={!submit_enabled} onClick={this.handleSubmitClick}>
                    {this.props.sighting_id ? 'Save Changes' : 'Create Sighting'}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='column is-half'>
            <div>
              {!this.props.sighting_id ? null : (
                <div className='card box is-hidden-touch'>
                  <div className='content'>
                    <p className='title is-4'>Location</p>
                    <p className='subtitle is-6'>{loc_str}</p>
                    <SightingMap
                      species_entry={species_entry}
                      latitude={this.state.sighting.latitude}
                      longitude={this.state.sighting.longitude}
                      height='400px'
                    />
                  </div>
                </div>
              )}
              {!this.props.sighting_id ? (
                <div className='card box'>
                  <div className='content'>
                    <p className='title is-4'>Images</p>
                  </div>
                  <div className='content' />
                  <p>Create the sighting before adding photos.</p>
                </div>
              ) : (
                <div className='card box'>
                  <div className='content'>
                    <p className='title is-4'>Images</p>
                  </div>
                  <div className='content'>
                    <PhotoThumbnails
                      photo_ids={this.state.sighting.sighting_photos}
                      networkfn={this.props.networkfn}
                      allow_delete={true}
                    />
                  </div>
                  <div className='content'>
                    <ImageDropzone
                      sighting_id={this.state.sighting.sighting_id}
                      network_fn={this.props.networkfn}
                      onCompletion={this._fetch}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
