import React, { useEffect, useCallback } from 'react';

export function ConfirmDialog(props) {
  const escFunction = useCallback(
    event => {
      if (event.keyCode === 27) {
        //Do whatever when esc is pressed
        props.onCancel();
      }
    },
    [props]
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      // Clean up the subscription
      document.removeEventListener('keydown', escFunction, false);
    };
  });

  return (
    <div id='confirmDialog' className={`modal is-active`}>
      <div className='modal-background' />
      <div className='modal-content'>
        <div className='modal-card'>
          <header className='modal-card-head'>
            <p className='modal-card-title'>{props.title}</p>
          </header>

          <section className='modal-card-body'>{props.body}</section>

          <footer className='modal-card-foot'>
            <div className='content is-pulled-right' style={{ width: '100%' }}>
              <button className='button is-primary is-link is-pulled-right' onClick={props.onCancel} autoFocus>
                Cancel
              </button>
              <button className='button is-pulled-right' onClick={props.onConfirm}>
                {props.actionName}
              </button>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
}
