import React, { useEffect } from 'react';

import { Helmet } from "react-helmet";
import { pfm } from './pfm-map';

import './whalemap.scss';

export function WhaleMap() {

    const options = {
        maxBounds: [
            [-89, -179],
            [89, 179]
        ],
        center: [20.7644, -156.4450],
        zoom: 10
    };

    useEffect(() => {
        if (window.mobile_app_location_override) {
            options.center[0] = window.mobile_app_location_override.lat;
            options.center[1] = window.mobile_app_location_override.lon;
        }
        if (navigator.geolocation) {
            let geoSuccess = (position) => {
                let lat = position.coords.latitude;
                let lon = position.coords.longitude;
                options.center[0] = lat;
                options.center[1] = lon;
                pfm.setup_and_load(options);
            };
            let geoError = () => {
                pfm.setup_and_load(options);
            }
            navigator.geolocation.getCurrentPosition(geoSuccess, geoError);
        } else {
            pfm.setup_and_load(options);
        }
    });

    return (

        <section className="whalemap section">

            <Helmet>
                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" />
            </Helmet>

            <div id="pwfmap"></div>

        </section>

    );

}
