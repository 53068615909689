import React, { useState, useEffect } from 'react';

import L from 'leaflet';
/*
import { pwfOpts } from '../../utils/_pfm-utils';

const SmallIcon = L.Icon.extend({
  options: {
    
    //    iconSize       : [o.iconSize[0]*h_scale,      o.iconSize[1]*v_scale],
    //    shadowSize     : [o.shadowSize[0]*h_scale,    o.shadowSize[1]*v_scale],
    //    iconAnchor     : [o.iconAnchor[0]*h_scale,    o.iconAnchor[1]*v_scale],
    //    popupAnchor    : [o.popupAnchor[0]*h_scale,   o.popupAnchor[1]*v_scale],
    //    tooltipAnchor  : [o.tooltipAnchor[0]*h_scale, o.tooltipAnchor[1]*v_scale]  

    iconSize: [24, 40],
    //shadowSize     : [20, 20],
    iconAnchor: [6, 20],
    popupAnchor: [1, -17],
    tooltipAnchor: [8, -14]
  }
});

function getSpeciesIcon(species_entry) {
  let icon = new SmallIcon({
    iconUrl: pwfOpts.iconurl + '/' + species_entry.icon,
    iconRetinaUrl: pwfOpts.iconurl + '/' + species_entry.icon2
    //shadowUrl      : pfm.pwfOpts.iconurl + '/' + 'marker-shadow.png',
  });
  return icon;
}
*/

/***
 * props.species_entry = species_entry, where species_entry is produced thusly:
 *      const species_obj = props.species_display_field;
 *      const species_entry = species_obj.allowed_values.find(av => av.key === this.state.sighting.species_code);
 * props.latitude = this.state.sighting.latitude
 * props.longitude = this.state.sighting.longitude
 * props.height = 400px if not supplied
 */
export function TrackMap(props) {
  const [map, set_map] = useState(null);

  window.L = L;

  const options = {
    maxBounds: [[-89.9, -179.9], [89.9, 179.9]]
  };

  useEffect(() => {
    let tile_layer = null;
    let gpx_layer = null;

    async function load_gpx() {
      if (!props.track) return;

      if (!map) {
        const tmp_map = L.map('mapid', options);
        set_map(tmp_map);
        return;
      }

      //map.setView(options.center, options.zoom);

      tile_layer = L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: 'Map data &copy; <a href="http://www.osm.org">OpenStreetMap</a>'
      }).addTo(map);

      if (props.track) {
        const raw_api_server = process.env.REACT_APP_API_SERVER ? process.env.REACT_APP_API_SERVER : '/';
        const api_server = raw_api_server.endsWith('/') ? raw_api_server.substr(0, raw_api_server.length - 1) : raw_api_server;

        const gpx_url = api_server + '/tracks/file/' + props.track.track_id;
        const icon_url = api_server + '/pins/';
        const _rsp = fetch(gpx_url, { credentials: 'include', method: 'GET' });
        const rsp = await _rsp;
        const text = await rsp.text();
        if (rsp.ok) {
          gpx_layer = new L.GPX(text, {
            async: true,
            marker_options: {
              startIconUrl: icon_url + 'pin-icon-start.png',
              endIconUrl: icon_url + 'pin-icon-end.png',
              shadowUrl: icon_url + 'pin-shadow.png'
            }
          })
            .on('loaded', function(e) {
              map.fitBounds(e.target.getBounds());
            })
            .addTo(map);
        }
      }
    }

    load_gpx();

    return () => {
      if (tile_layer) map.removeLayer(tile_layer);
      //if (marker) map.removeLayer(marker);
      if (gpx_layer) map.removeLayer(gpx_layer);
    };
  }, [map, options, props.track]);

  return (
    <div className='content'>
      <div id='mapid' style={{ height: props.height ? props.height : '400px' }} />
    </div>
  );
}
