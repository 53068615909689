
const moment = require('moment-timezone');

// These are copies from things from components/map/pfm-map.js

export const pwfOpts = {};

const raw_api_server = process.env.REACT_APP_API_SERVER ? process.env.REACT_APP_API_SERVER : '/';
const api_server = raw_api_server.endsWith('/') ? raw_api_server.substr(0, raw_api_server.length-1) : raw_api_server ;

pwfOpts.iconurl = api_server + '/icon';
pwfOpts.imgurl = api_server + '/img';

export function formatSightingDateTime(date_and_time_utc_as_string) {
    // From pfm-map.js

    var sd = new Date(date_and_time_utc_as_string);
    var fsd = moment(sd).format('MMM DD YYYY, HH:mm ');
    var tzName = moment.tz.guess();
    var abbr = moment.tz(tzName).zoneAbbr();
    fsd += abbr;

    return fsd;
}