import React, { useState, useCallback, useEffect } from 'react';
//import history from '../../utils/history';
//import { rootpath } from '../../utils/rootpath';

const tracks_state = {
  NOT_STARTED: 'not_started',
  LOAD: 'load', // loading next tracks
  BEGIN_PROCESSING: 'begin_processing',
  PROCESSING: 'processing',
  LOADED: 'loaded',
  ERROR: 'error'
};

const loading_message = msg => (
  <div style={{ textAlign: 'center' }} className='card box'>
    {msg ? msg : 'Loading...'}
  </div>
);

const error_message = msg => (
  <div style={{ textAlign: 'center' }} className='card box'>
    {msg ? msg : 'Unknown error.'}
  </div>
);

const max_to_fetch = 100;

function TracksTable(props) {
  return (
    <div className='row'>
      <table id='trackstable' className='table is-striped is-bordered is-fullwidth'>
        <thead>
          <tr>
            <th className='is-hidden-mobile' data-field='username'>
              User
            </th>
            <th data-field='start'>Track Start</th>
            <th className='is-hidden-mobile' data-field='track_key_value'>
              Track Key
            </th>
            <th>Sightings</th>
            <th data-field='download' className='is-hidden-mobile'>
              <i className='fa fa-download is-hidden-tablet' aria-hidden='true' />
              <span className='is-hidden-mobile'>Download</span>
            </th>
          </tr>
        </thead>
        <tbody>{props.tracks}</tbody>
      </table>
    </div>
  );
}

export function TracksPage(props) {
  const [state, set_state] = useState(tracks_state.NOT_STARTED);
  const [tracks, set_tracks] = useState([]);
  const [rendered_tracks, set_rendered_tracks] = useState([]);
  const [tracks_remaining, set_tracks_remaining] = useState(true);
  const [output, set_output] = useState(null);
  const [load_slot, set_load_slot] = useState(null);
  const [offset, set_offset] = useState(0);

  const _fetch = useCallback(async () => {
    const tracks_url = '/tracks?offset=' + offset + '&limit=' + (max_to_fetch + 1);
    const _rsp = props.networkfn(process.env.REACT_APP_API_SERVER + tracks_url, {});
    const rsp = await _rsp;

    if (rsp.ok) {
      const returned_tracks = rsp.returned_body;
      set_tracks(returned_tracks);
      set_offset(offset + max_to_fetch);
      set_state(tracks_state.BEGIN_PROCESSING);
    } else {
      if (window._do_pwf_debug_log) console.log(rsp);
      set_state(tracks_state.ERROR);
    }
  }, [set_tracks, set_offset, offset, props]);

  const _process = useCallback(() => {
    set_state(tracks_state.PROCESSING);

    // Chop of last entry of the returned array. We grab one more than we need
    // to determine if more exist to fetch.

    const more_to_fetch = tracks.length === max_to_fetch + 1;

    const tracks_to_process = more_to_fetch ? tracks.slice(0, -1) : tracks;

    const processed_tracks = tracks_to_process.map(track => {
      let elt = (
        <tr key={track.track_id}>
          <td className='is-hidden-mobile'>{track.username}</td>
          <td>
            <a href={'/view_track/' + track.track_id} className='app-link'>
              {track.start_date_utc}
            </a>
          </td>
          <td className='is-hidden-mobile'>
            <a href={'/view_track/' + track.track_id} className='app-link'>
              {track.track_key}
            </a>
          </td>
          <td>
            {track.track_sightings ? track.track_sightings.length : ''}
          </td>
          <td className='is-hidden-mobile'>
            <a href={process.env.REACT_APP_API_SERVER + '/tracks/file/' + track.track_id}>
            <i title='Download' className='fa fa-download' />
            </a>
          </td>
        </tr>
      );
      return elt;
    });

    set_tracks_remaining(more_to_fetch);
    set_rendered_tracks(rendered_tracks.concat(processed_tracks));
    set_state(tracks_state.LOADED);
  }, [set_state, tracks, rendered_tracks]);

  useEffect(() => {
    if (window._do_pwf_debug_log) console.log('USE EFFECT ENTRY with state: ' + state);
    switch (state) {
      case tracks_state.NOT_STARTED:
        _fetch();
        set_state(tracks_state.INITIAL_LOAD);
        break;
      case tracks_state.INITIAL_LOAD:
        set_load_slot(loading_message());
        break;
      case tracks_state.LOAD:
        set_output(<TracksTable tracks={rendered_tracks} />);
        set_load_slot(loading_message());
        break;
      case tracks_state.BEGIN_PROCESSING:
        _process();
        break;
      case tracks_state.PROCESSING:
        break;
      case tracks_state.LOADED:
        set_output(<TracksTable tracks={rendered_tracks} />);
        set_load_slot(
          tracks_remaining ? (
            <section className='section container has-text-centered'>
              <button
                className='button is-large is-link'
                onClick={() => {
                  set_state(tracks_state.LOAD);
                  _fetch();
                }}
              >
                Load {max_to_fetch} more tracks
              </button>
            </section>
          ) : null
        );

        if (window._do_pwf_debug_log) console.log('DONE');
        break;
      case tracks_state.ERROR:
        set_output(error_message());
        break;
      default:
        set_output(error_message('Unknown control state.'));
        throw new Error('Unknown state.');
    }
  }, [_fetch, tracks_remaining, _process, state, rendered_tracks]);

  return (
    <section className='section'>
      <div className='columns is-centered'>
        <div className='column is-full'>
          <div className='sightingslist container'>
            {output}
            {load_slot}
          </div>
        </div>
      </div>
    </section>
  );
}
