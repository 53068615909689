import React, { useState, useCallback, useEffect } from 'react';
import history from '../../utils/history';
import { rootpath } from '../../utils/rootpath';

import { SightingMedia } from './sighting_media';
import { SightingTable } from './sighting_table';

const sightings_state = {
  NOT_STARTED: 'not_started',
  INITIAL_LOAD: 'initial_load', // loading schema and sightings
  LOAD: 'load', // loading sightings
  BEGIN_PROCESSING: 'begin_processing',
  PROCESSING: 'processing',
  LOADED: 'loaded',
  ERROR: 'error'
};

const loading_message = msg => (
  <div style={{ textAlign: 'center' }} className='card box'>
    {msg ? msg : 'Loading...'}
  </div>
);

const error_message = msg => (
  <div style={{ textAlign: 'center' }} className='card box'>
    {msg ? msg : 'Unknown error.'}
  </div>
);

const max_to_fetch = 50;

export function SightingsPage(props) {
  const [state, set_state] = useState(sightings_state.NOT_STARTED);
  const [sightings, set_sightings] = useState([]);
  const [rendered_sightings, set_rendered_sightings] = useState([]);
  const [schema, set_schema] = useState(null);
  const [schema_display_fields, set_schema_display_fields] = useState(null);
  const [sightings_remaining, set_sightings_remaining] = useState(true);
  const [output, set_output] = useState(null);

  const [offset, set_offset] = useState(0);

  const _fetch = useCallback(async () => {
    const _schema = props.networkfn(process.env.REACT_APP_API_SERVER + '/schema', {});

    const sightings_url = props.sighting_ids
      ? '/sightings?ids=' + JSON.stringify(props.sighting_ids)
      : '/sightings?offset=' + offset + '&limit=' + (max_to_fetch + 1);

    const _rsp = props.networkfn(process.env.REACT_APP_API_SERVER + sightings_url, {});

    // Await later so that tasks can complete in parallel.
    const rsp = await _rsp;
    const schema = await _schema;

    if (rsp.ok && schema.ok) {
      const returned_sightings = rsp.returned_body;
      const returned_schema = schema.returned_body.schema;
      // Cache the schema definitions
      const schema_display_fields = {};
      for (let schema_field in returned_schema.fields) {
        let field = returned_schema.fields[schema_field];
        schema_display_fields[field.field_name] = field;
      }

      set_schema(returned_schema);
      set_schema_display_fields(schema_display_fields);
      set_sightings(returned_sightings);
      set_offset(offset + max_to_fetch);
      set_state(sightings_state.BEGIN_PROCESSING);
    } else {
      if (window._do_pwf_debug_log) console.log(rsp);
      set_state(sightings_state.ERROR);
    }
  }, [set_sightings, set_schema, set_schema_display_fields, set_offset, offset, props]);

  const _fetch_next = useCallback(async () => {
    const sightings_url = '/sightings?offset=' + offset + '&limit=' + (max_to_fetch + 1);
    const _rsp = props.networkfn(process.env.REACT_APP_API_SERVER + sightings_url, {});

    // Await later so that tasks can complete in parallel.
    const rsp = await _rsp;

    if (rsp.ok) {
      const returned_sightings = rsp.returned_body;
      set_sightings(returned_sightings);
      set_offset(offset + max_to_fetch);
      set_state(sightings_state.BEGIN_PROCESSING);
    } else {
      if (window._do_pwf_debug_log) console.log(rsp);
      set_state(sightings_state.ERROR);
    }
  }, [set_sightings, set_offset, offset, props]);

  const _process = useCallback(() => {
    set_state(sightings_state.PROCESSING);

    // Chop of last entry of the returned array. We grab one more than we need
    // to determine if more exist to fetch.

    const more_to_fetch = sightings.length === max_to_fetch + 1;

    const sightings_to_process = more_to_fetch ? sightings.slice(0, -1) : sightings;

    const processed_sightings = sightings_to_process.map(sighting => {
      const species_schema_definition = schema_display_fields.species_code;

      if (!species_schema_definition) return null;

      const species_entry = species_schema_definition.allowed_values.find(av => av.key === sighting.species_code);

      if (!species_entry) return null;

      // Three special-case fields: 'species_code', 'sighting_date_and_time_utc', and 'Has Photos?' (built from sighting_photos)

      let elt = (
        <div className='card box' key={sighting.sighting_id}>
          <SightingMedia species_entry={species_entry} sighting_date_and_time_utc={sighting.sighting_date_and_time_utc} />

          <SightingTable
            schema={schema}
            sighting={sighting}
            cached_schema_fields={schema_display_fields}
            display_fields={schema.display_fields}
            show_has_photos={true}
          />

          <footer className='card-footer'>
            <a href={'/view_sighting/' + sighting.sighting_id} className='card-footer-item app-link'>
              View
            </a>
            {window.mobile_app_override ? null : (
            <a href={'/edit_sighting/' + sighting.sighting_id} className='card-footer-item app-link'>
              Edit
            </a>)}
          </footer>
        </div>
      );
      return elt;
    });

    set_sightings_remaining(more_to_fetch);
    set_rendered_sightings(rendered_sightings.concat(processed_sightings));
    set_state(sightings_state.LOADED);
  }, [set_state, schema, schema_display_fields, sightings, rendered_sightings]);

  useEffect(() => {
    if (window._do_pwf_debug_log) console.log('USE EFFECT ENTRY with state: ' + state);
    switch (state) {
      case sightings_state.NOT_STARTED:
        _fetch();
        set_state(sightings_state.INITIAL_LOAD);
        break;
      case sightings_state.INITIAL_LOAD:
        set_output(loading_message());
        break;
      case sightings_state.LOAD:
        set_output(
          <div>
            {rendered_sightings}
            {loading_message()}
          </div>
        );
        break;
      case sightings_state.BEGIN_PROCESSING:
        _process();
        break;
      case sightings_state.PROCESSING:
        break;
      case sightings_state.LOADED:
        set_output(
          <div>
            {rendered_sightings}
            {sightings_remaining ? (
              <div className='container has-text-centered'>
                <button
                  className='button is-large is-link'
                  onClick={() => {
                    set_state(sightings_state.LOAD);
                    _fetch_next();
                  }}
                >
                  Load {max_to_fetch} more sightings
                </button>
              </div>
            ) : null}
          </div>
        );

        if (window._do_pwf_debug_log) console.log('DONE');
        break;
      case sightings_state.ERROR:
        set_output(error_message());
        break;
      default:
        set_output(error_message('Unknown control state.'));
        throw new Error('Unknown state.');
    }
  }, [_fetch, _fetch_next, sightings_remaining, _process, state, rendered_sightings]);

  return ( props.strip || window.mobile_app_override ) ? (
    <div className='sightingslist'>{output}</div>
  ) : (
    <section className='section'>
      <div className='columns is-centered'>
        <div className='column is-half'>
          <div className='sightingslist'>{output}</div>
        </div>
      </div>
      <a
        title='Download all my sightings'
        target='_blank' rel="noopener noreferrer"
        className='is-hidden-mobile button export-sightings is-large is-rounded is-link is-outlined'
        href={process.env.REACT_APP_API_SERVER + '/sightings/export'}
      >
        <i className='fa fa-download' aria-hidden='true' /> &nbsp; Download
      </a>
      <button
        title='Add a new sighting'
        className='is-hidden-mobile button add-sightings is-large is-rounded is-link'
        onClick={() => history.push({ pathname: rootpath + '/add_sighting' })}
      >
        <i className='fa fa-plus' aria-hidden='true' /> &nbsp; Add sighting
      </button>
      <button
        className='is-hidden-tablet button add-sightings-mobile is-large is-rounded is-link'
        onClick={() => history.push({ pathname: rootpath + '/add_sighting' })}
      >
        <i className='fa fa-plus' aria-hidden='true' />
      </button>
    </section>
  );
}
