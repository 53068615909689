import React, { useState, useEffect } from 'react';
import ReactTable from "react-table";
import history from '../../utils/history';
import { rootpath } from '../../utils/rootpath';
import "react-table/react-table.css";

import { ConfirmDialog } from '../dialog/confirmdialog';

export function UserTable(props) {

    const [users, set_users] = useState([]);
    const [user_to_delete, set_user_to_delete] = useState({});
    const [error, set_error] = useState(null);

    const _destroy = async () => {
        if (!user_to_delete || !user_to_delete.id) return;
        const rsp = await props.networkfn(process.env.REACT_APP_API_SERVER + '/users/id/' + user_to_delete.id, { method: 'DELETE' });
        if (rsp.ok) {
            set_user_to_delete({});
        } else {
            set_users([]);
            set_user_to_delete({});
            set_error(rsp);
        }
    }

    useEffect(() => {

        async function _fetch() {
            const rsp = await props.networkfn(process.env.REACT_APP_API_SERVER + '/users/all', {});
            if (rsp.ok) {
                set_users(rsp.returned_body);
            } else {
                set_users([]);
                set_error(rsp);
            }
        }

        _fetch();

    }, [user_to_delete, props]);

    if (window._do_pwf_debug_log && error) console.log(error);

    if (user_to_delete.username) {

        const bodyNode = <div>
            <p>Are you sure you want to delete the user <i>{user_to_delete.username}</i>?</p>
            <p className="has-text-weight-bold">Warning: This operation cannot be undone.</p>
        </div>;

        const titleNode = <span>Confirm Deletion: <i>{user_to_delete.username}</i></span>;

        return (
            <ConfirmDialog
                title={titleNode}
                body={bodyNode}
                actionName='Delete'
                onCancel={() => { set_user_to_delete({}) }}
                onConfirm={_destroy}> </ConfirmDialog>
        );

    } else {

        return (

            <section className="section">

                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                        </div>
                    </div>
                    <div className="level-right">
                        <div className="level-item">
                            <button className="button is-link" onClick={() => history.push({ pathname: rootpath + `/useradd` })}>
                                <i className="fa fa-plus" aria-hidden="true"></i> &nbsp; Add new user
                                </button>
                        </div>
                    </div>
                </div>

                <ReactTable
                    defaultPageSize={10}
                    defaultSorted={[
                        {
                            id: "username",
                            desc: false
                        }
                    ]}
                    filterable={true}
                    data={users}
                    resolveData={data => data.map((row) => {
                        let user = row;
                        user.editHtml = <button
                            className="button is-small"
                            onClick={() => history.push({ pathname: rootpath + `/useredit/` + user.uid })}>
                            Edit
                                </button>;

                        user.deleteHtml = <button
                            className="button is-small"
                            onClick={() => set_user_to_delete({ username: user.username, id: user.uid })}>
                            Delete
                              </button>;
                        return row;
                    })}
                    columns={
                        [
                            {
                                Header: "System ID",
                                accessor: "uid"
                            },
                            {
                                Header: "Username",
                                accessor: "username"
                            },
                            {
                                Header: "Email",
                                id: "email",
                                accessor: d => d.email
                            },
                            {
                                Header: "Is Admin?",
                                accessor: "is_admin",
                                Cell: row => (
                                    <span>
                                        <input type="checkbox" onClick={e => e.preventDefault()} onChange={() => { }} checked={row.value ? 1 : 0} />
                                    </span>
                                )
                            },
                            {
                                Header: "Is Researcher?",
                                accessor: "is_researcher",
                                Cell: row => (
                                    <span>
                                        <input type="checkbox" onClick={e => e.preventDefault()} onChange={() => { }} checked={row.value ? 1 : 0} />
                                    </span>
                                )
                            },
                            {
                                Header: "Date Created",
                                accessor: "created"
                            },
                            {
                                Header: "Edit",
                                accessor: "editHtml",
                                filterable: false
                            },
                            {
                                Header: "Delete",
                                accessor: "deleteHtml",
                                filterable: false
                            }
                        ]
                    }
                    className="-striped -highlight"
                />
            </section>

        );

    }

}