import React, { useState, useEffect } from 'react';

import L from 'leaflet';
import { pwfOpts } from '../../utils/_pfm-utils';

const SmallIcon = L.Icon.extend({
  options: {
    /*
        iconSize       : [o.iconSize[0]*h_scale,      o.iconSize[1]*v_scale],
        shadowSize     : [o.shadowSize[0]*h_scale,    o.shadowSize[1]*v_scale],
        iconAnchor     : [o.iconAnchor[0]*h_scale,    o.iconAnchor[1]*v_scale],
        popupAnchor    : [o.popupAnchor[0]*h_scale,   o.popupAnchor[1]*v_scale],
        tooltipAnchor  : [o.tooltipAnchor[0]*h_scale, o.tooltipAnchor[1]*v_scale]
        */

    iconSize: [24, 40],
    //shadowSize     : [20, 20],
    iconAnchor: [6, 20],
    popupAnchor: [1, -17],
    tooltipAnchor: [8, -14]
  }
});

function getSpeciesIcon(species_entry) {
  let icon = new SmallIcon({
    iconUrl: pwfOpts.iconurl + '/' + species_entry.icon,
    iconRetinaUrl: pwfOpts.iconurl + '/' + species_entry.icon2
    //shadowUrl      : pfm.pwfOpts.iconurl + '/' + 'marker-shadow.png',
  });
  return icon;
}

/***
 * props.species_entry = species_entry, where species_entry is produced thusly:
 *      const species_obj = props.species_display_field;
 *      const species_entry = species_obj.allowed_values.find(av => av.key === this.state.sighting.species_code);
 * props.latitude = this.state.sighting.latitude
 * props.longitude = this.state.sighting.longitude
 * props.height = 400px if not supplied
 */
export function SightingMap(props) {
  const [map, set_map] = useState(null);

  const raw_lat = Number(props.latitude);
  const raw_lon = Number(props.longitude);

  const lat = ( isNaN(raw_lat) || isNaN(raw_lon) ) ? 20.709527 : raw_lat;
  const lon = ( isNaN(raw_lat) || isNaN(raw_lon) ) ? -156.651925 : raw_lon;

  const options = {
    maxBounds: [[-89.9, -179.9], [89.9, 179.9]],
    center: [lat, lon],
    zoom: 10
  };

  useEffect(() => {
    if (!props.species_entry) return;

    if (!map) {
      set_map(L.map('mapid', options));
      return;
    }

    map.setView(options.center, options.zoom);

    const tile_layer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: 'Map data &copy; <a href="http://www.osm.org">OpenStreetMap</a>'
    }).addTo(map);

    const marker = L.marker([lat, lon], {
      icon: getSpeciesIcon(props.species_entry)
    }).addTo(map);

    return () => {
      if (tile_layer) map.removeLayer(tile_layer);
      if (marker) map.removeLayer(marker);
    };

  }, [map, options, props.species_entry, lat, lon]);

  return (
    <div className='content'>
      <div id='mapid' style={{ height: props.height ? props.height : '400px' }} />
    </div>
  );
}
