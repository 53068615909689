
import pathToRegexp from 'path-to-regexp';
import { rootpath } from './rootpath';

class MiniRoute {
    constructor(route) {

        for (var p in route) {
            if (route.hasOwnProperty(p)) {
                this[p] = route[p];
            }
        }

        let keys = [];
        let regexp = pathToRegexp( rootpath + route.path, keys, { strict: false });
        this.keys = keys;
        this.regexp = regexp;

        this.path = rootpath + route.path; // Make it easy (easier) to change application base URL.
    }

    exec(location) {
        return this.regexp.exec(location);
    }
}

export class MiniRouter {

    constructor(route_list) {
        this.compiled_routes = route_list.map(v => new MiniRoute(v));
    }

    evaluate(pathname) {

        let evaluated_routes = this.compiled_routes
            .map(v => ({ ...v, result: v.exec(pathname) }))
            .filter(v => v.result)
            .map(v => {
                let match = null;
                if (v.keys && v.keys.length) {
                    match = {};
                    for (let i = 0; i < v.keys.length; ++i) {
                        let key = v.keys[i].name;
                        let val = v.result && v.result.length ? v.result[i + 1] : null;
                        match[key] = val;
                    }
                }
                return { match: match, ...v };
            });

        return evaluated_routes;
    }

}