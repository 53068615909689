import React, { useState, useCallback } from 'react';

import logo from '../../images/pwf_logo.jpg'; // with import

export function LoginForm(props) {

  const [username, set_username] = useState('');
  const [password, set_password] = useState('');

  const handleSubmitClick = useCallback(
    e => {
      e.preventDefault();
      if (props.submitfn) {
        props.submitfn(username, password);
      }
    },
    [props, username, password]
  );

  const handleInputChange = useCallback(e => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'username') set_username(value);
    if (name === 'password') set_password(value);
  }, []);

  // If you put other buttons in the form, they must have "type="button"" on them. Because... go read this:
  // https://stackoverflow.com/questions/932653/how-to-prevent-buttons-from-submitting-forms

  const mobile_elt = (
    <p className='has-text-grey'>
      Please use the "Back" button (above) to return to the main app screen, then log in to the App.
    </p>
  );

  const web_elt = (
    <div>
      <form>
        <div className='field'>
          <div className='control'>
            <input
              name='username'
              value={username}
              onChange={handleInputChange}
              className='input is-large'
              type='text'
              placeholder='Your Username'
              autoFocus=''
            />
          </div>
        </div>

        <div className='field'>
          <div className='control'>
            <input
              name='password'
              value={password}
              onChange={handleInputChange}
              className='input is-large'
              type='password'
              placeholder='Your Password'
            />
          </div>
        </div>

        <div>
          <p className={`help ${props.errormsg ? 'is-danger' : 'is-hidden'}`}>{props.errormsg}</p>
        </div>

        <button className='button is-block is-info is-large is-fullwidth' onClick={handleSubmitClick}>
          Log In
                </button>
      </form>
    </div>
  );

  const sign_in = (
    <p className='has-text-grey'>
      <a className='app-link' href='/signup'>
        Sign Up
              </a>
              &nbsp; &nbsp;
      <a className='app-link' href='/reset_password'>
        Forgot Password
              </a>
    </p>
  );

  return (
    <section className='hero is-fullheight'>
      <div className='hero-body'>
        <div className='container has-text-centered'>
          <div className='column is-6 is-offset-3'>
            <h3 className='title has-text-grey'>Log In</h3>
            <p className='subtitle has-text-grey'>Please log in to proceed.</p>
            <div className='box'>
              <figure className='avatar'>
                <img alt='Logo' width='128' height='128' src={logo} />
              </figure>
              {(window.mobile_app_override) ? mobile_elt : web_elt }
            </div>
            {(window.mobile_app_override) ? null : sign_in}
          </div>
        </div>
      </div>
    </section>
  );
}
