import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { TrackMap } from './track_map';
import { SightingsPage } from '../sightings/sightings_page';

export function TrackViewPage(props) {
  const [track, set_track] = useState(null);

  const _fetch = useCallback(async () => {
    const _rsp = props.networkfn(process.env.REACT_APP_API_SERVER + '/tracks/id/' + props.track_id, {});

    // Await later so that tasks can complete in parallel.

    const rsp = await _rsp;

    if (rsp.ok) {
      set_track(rsp.returned_body);
    } else {
      if (window._do_pwf_debug_log) console.log(rsp);
      toast.error('Unknown error');
      set_track(null);
    }
  }, [props, set_track]);

  useEffect(() => {
    _fetch();
  }, [_fetch]);

  if (!track) {
    return 'Loading...';
  }

  return (
    <React.Fragment>
      <section className='section'>
        <div className='columns is-centered'>
          <div className='column is-half'>
            <div className='card box'>
              <div className='content'>
                <p className='title is-4'>Track Details</p>

                <div className='columns'>
                  <div className='column is-one-quarter'>
                    <strong>User</strong>
                  </div>
                  <div className='column is-three-quarters'>{track.username}</div>
                </div>
                <div className='columns'>
                  <div className='column is-one-quarter'>
                    <strong>Track Start</strong>
                  </div>
                  <div className='column is-three-quarters'>{track.start_date_utc}</div>
                </div>
                <div className='columns'>
                  <div className='column is-one-quarter'>
                    <strong>Track Key</strong>
                  </div>
                  <div className='column is-three-quarters'>{track.track_key}</div>
                </div>
                <div className='columns'>
                  <div className='column is-one-quarter'>
                    <strong>Sightings</strong>
                  </div>
                  <div className='column is-three-quarters'>{track.track_sightings ? track.track_sightings.length : 0}</div>
                </div>
                <div className='columns'>
                  <div className='column is-one-quarter'>
                    <strong>Download</strong>
                  </div>
                  <div className='column is-three-quarters'>
                    <a href={process.env.REACT_APP_API_SERVER + '/tracks/file/' + track.track_id}>
                      <i title='Download' className='fa fa-download' />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='columns is-centered'>
          <div className='column is-half'>
            <div className='card box'>
              <div className='content'>
                <p className='title is-4'>Track Map</p>
                <TrackMap height='400px' track={track} />
              </div>
            </div>
          </div>
        </div>
      </section>

      {track.track_sightings && track.track_sightings.length ? (
        <section className='section'>
          <div className='columns is-centered'>
            <div className='column is-half'>
              <div className='card box'>
                <div className='content'>
                  <p className='title is-4'>Sighting Details</p>
                </div>
                <SightingsPage networkfn={props.networkfn} strip={true} sighting_ids={track.track_sightings} />
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </React.Fragment>
  );
}
