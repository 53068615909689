import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';

function Progress(props) {
  return (
    <div className='ProgressBar'>
      <div className='Progress' style={{ width: props.progress + '%' }} />
    </div>
  );
}

export function ImageDropzone(props) {

  const [files, set_files] = useState([]);
  const [last_file_key, set_last_file_key] = useState(0);
  const [uploading, set_uploading] = useState(false);

  async function sendRequest(file_entry) {
    const formData = new FormData();
    formData.append('sighting_id', props.sighting_id);
    if (file_entry.file.lastModified) {
      formData.append('date_created_utc', new Date(file_entry.file.lastModified).toISOString());
    }
    formData.append('image', file_entry.file);

    file_entry.err_msg = null;

    return axios.post(process.env.REACT_APP_API_SERVER + '/photos/create', formData, {
      withCredentials: true,
      cancelToken: new axios.CancelToken(c_fn => (file_entry.cancel = c_fn)),
      headers: {
        'Content-Type': 'multipart/form-data'
      },

      onUploadProgress: progressEvent => {
        if (window._do_pwf_debug_log) console.log(progressEvent);
        const copy = files.slice(0);
        const done = progressEvent.loaded === progressEvent.total;
        const entry_index = files.findIndex(e => file_entry.key === e.key);
        const entry = files[entry_index];
        entry.state = done ? 'done' : 'pending';
        entry.percentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        copy[entry_index] = entry;
        set_files(copy);
      }
    });
  }

  function renderProgress(file_entry) {
    if (window._do_pwf_debug_log) console.log(file_entry);
    return file_entry.err_msg ? (
      <div className='Content Error'>{file_entry.err_msg}</div>
    ) : (
      <div className='ProgressWrapper'>
        <Progress progress={file_entry ? file_entry.percentage : 0} />
        <img
          className='CheckIcon'
          alt='done'
          src='../baseline-check_circle_outline-24px.svg'
          style={{
            opacity: file_entry && file_entry.state === 'done' ? 0.5 : 0
          }}
        />
      </div>
    );
  }

  const onDrop = useCallback(
    async acceptedFiles => {
      // Do something with the files
      if (window._do_pwf_debug_log) console.log(acceptedFiles);

      let new_files = [];
      let cur_key = last_file_key;

      for (const file of acceptedFiles) {
        try {
          new_files = new_files.concat([{ key: cur_key, file: file, state: 'not_started', percentage: 0 }]);
          cur_key += 1;
        } catch (e) {
          if (window._do_pwf_debug_log) console.log('ERR');
          if (window._do_pwf_debug_log) console.log(e);
        }
      }

      set_last_file_key(cur_key);
      set_files(new_files);
    },
    [set_last_file_key, last_file_key, set_files]
  );

  // eslint-disable-next-line
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: onDrop, disabled: uploading });

  if (!props.sighting_id) return null;

  async function test_and_upload() {
    let upload_required = false;

    if (!uploading && files && files.length > 0) {
      for (const target of files) {
        if (target.state === 'not_started') upload_required = true;
      }
    }

    if (!upload_required) return;

    set_uploading(true);

    for (const target of files) {
      try {
        if (target.state !== 'not_started') continue;
        let response = await sendRequest(target);
        if (window._do_pwf_debug_log) console.log(response);
      } catch (error) {
        target.state = 'error';
        if (axios.isCancel(error)) {
          if (window._do_pwf_debug_log) console.log('Request canceled');
          error.message = 'Upload canceled';
        } else {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (window._do_pwf_debug_log) console.log(error.response.data);
            if (window._do_pwf_debug_log) console.log(error.response.status);
            if (window._do_pwf_debug_log) console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            if (window._do_pwf_debug_log) console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            if (window._do_pwf_debug_log) console.log('Error', error.message);
          }
          if (window._do_pwf_debug_log) console.log(error.config);

          if (window._do_pwf_debug_log) console.log(
            JSON.stringify(error, (key, value) => {
              if (value instanceof Error) {
                var error = {};

                Object.getOwnPropertyNames(value).forEach(function(key) {
                  error[key] = value[key];
                });

                return error;
              }

              return value;
            })
          );
        }
        target.err_msg = error.message;
      }
    }

    set_uploading(false);

    if (props.onCompletion) {
      props.onCompletion();
    }

    if (window._do_pwf_debug_log) console.log('Done');

  }

  test_and_upload();

  return (
    <div>
      <div {...getRootProps({ className: 'dropzone' + (uploading ? ' disabled' : '') })}>
        <input {...getInputProps()} />
        <p>Drop photos here, or click to select files.</p>
      </div>
      <div className='Files'>
        {files.map(file => {
          return (
            <div key={file.key} className='Row'>
              <span className='Filename'>{file.file.name}</span>
              {renderProgress(file)}
            </div>
          );
        })}
      </div>
    </div>
  );
}
