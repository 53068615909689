import React, { useState, useCallback } from 'react';

import logo from '../../images/pwf_logo.jpg'; // with import

import { PasswordSuccess } from './password_success';

export function PasswordReset(props) {
  const [email_address, set_email_address] = useState('');
  const [success, set_success] = useState(null);
  const [error, set_error] = useState(null);

  const fn_reset = useCallback(async function(email_address) {
    const data = {
      email: email_address
    };

    const opts = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };

    let rv = await props.networkfn(process.env.REACT_APP_API_SERVER + '/users/reset_password', opts);

    if (rv.ok) {
      set_success(true);
    } else {
      let errmsg = 'An unknown error occurred';

      if (rv && rv.returned_body && rv.returned_body.message) {
        errmsg = 'An error occurred: ' + rv.returned_body.message;
      } else if (rv.status_text) {
        errmsg = 'An error occurred: ' + rv.status_text;
      }

      set_error(errmsg);
    }
  }, [props, set_success, set_error]);

  const handleSubmitClick = useCallback(
    e => {
      e.preventDefault();
      set_error(null);
      set_success(null);
      fn_reset(email_address);
    },
    [set_error, set_success, fn_reset, email_address]
  );

  const handleInputChange = useCallback(e => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'email_address') set_email_address(value);
  }, []);

  // If you put other buttons in the form, they must have "type="button"" on them. Because... go read this:
  // https://stackoverflow.com/questions/932653/how-to-prevent-buttons-from-submitting-forms

  return success ? (
    <PasswordSuccess email={email_address} />
  ) : (
    <section className='hero is-fullheight'>
      <div className='hero-body'>
        <div className='container has-text-centered'>
          <div className='column is-6 is-offset-3'>
            <h3 className='title has-text-grey'>Enter Email</h3>
            <p className='subtitle has-text-grey'>Please enter your email address</p>
            <div className='box'>
              <figure className='avatar'>
                <img alt='Logo' width='128' height='128' src={logo} />
              </figure>
              <form>
                <div className='field'>
                  <div className='control'>
                    <input
                      name='email_address'
                      value={email_address}
                      onChange={handleInputChange}
                      className='input is-large'
                      type='email'
                      placeholder='Your Email'
                      autoFocus=''
                    />
                  </div>
                </div>

                <div className='content'>
                  <div className='content has-text-centered has-text-danger'>{error}</div>
                </div>

                <button className='button is-block is-info is-large is-fullwidth' onClick={handleSubmitClick}>
                  Reset my Password
                </button>
              </form>
            </div>
            <p className='has-text-grey'>
              <a className='app-link' href='/signup'>
                Sign Up
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
