import 'whatwg-fetch';
import L from 'leaflet';
import Pikaday from 'pikaday';
import 'leaflet-providers';
import 'leaflet-fullscreen';
import './pwf-sidebar';
import './pfm-map.scss';
import { pfm_species_map } from './species_map';

import 'pikaday/css/pikaday.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';

const moment = require('moment-timezone');

function createElementFromHTML(htmlString) {
    var div = document.createElement('div');
    div.innerHTML = htmlString.trim();

    // Change this to div.childNodes to support multiple top-level nodes
    return div.firstChild;
}

// Define a custom control:

L.Control.Custom = L.Control.extend({

    version: '1.0.1',

    options: {
        position: 'topright',
        id: '',
        title: '',
        classes: '',
        content: '',
        style: {},
        datas: {},
        events: {},
    },

    container: null,

    // eslint-disable-next-line
    onAdd: function (map) {
        this.container = L.DomUtil.create('div');
        this.container.id = this.options.id;
        this.container.title = this.options.title;
        this.container.className = this.options.classes;
        this.container.innerHTML = this.options.content;

        for (var option in this.options.style) {
            this.container.style[option] = this.options.style[option];
        }

        for (var data in this.options.datas) {
            this.container.dataset[data] = this.options.datas[data];
        }


        /* Prevent click events propagation to map */
        L.DomEvent.disableClickPropagation(this.container);

        /* Prevent right click event propagation to map */
        L.DomEvent.on(this.container, 'contextmenu', function (ev) {
            L.DomEvent.stopPropagation(ev);
        });

        /* Prevent scroll events propagation to map when cursor on the div */
        L.DomEvent.disableScrollPropagation(this.container);

        for (var event in this.options.events) {
            L.DomEvent.on(this.container, event, this.options.events[event], this.container);
        }

        return this.container;
    },

    // eslint-disable-next-line
    onRemove: function (map) {
        for (var event in this.options.events) {
            L.DomEvent.off(this.container, event, this.options.events[event], this.container);
        }
    },
});

L.control.custom = function (options) {
    return new L.Control.Custom(options);
};

// Done with custom control

// let o = JSON.parse(JSON.stringify(L.Icon.Default.prototype.options));

let SmallIcon = L.Icon.extend({
    options: {
        /*
        iconSize       : [o.iconSize[0]*h_scale,      o.iconSize[1]*v_scale],
        shadowSize     : [o.shadowSize[0]*h_scale,    o.shadowSize[1]*v_scale],
        iconAnchor     : [o.iconAnchor[0]*h_scale,    o.iconAnchor[1]*v_scale],
        popupAnchor    : [o.popupAnchor[0]*h_scale,   o.popupAnchor[1]*v_scale],
        tooltipAnchor  : [o.tooltipAnchor[0]*h_scale, o.tooltipAnchor[1]*v_scale]
        */

        iconSize: [12, 20],
        //shadowSize     : [20, 20],
        iconAnchor: [6, 20],
        popupAnchor: [1, -17],
        tooltipAnchor: [8, -14]

    }
});

export let pfm = {};

pfm.options = {
    openPopupOnHover: false,
    USER_CAN_HOVER: false
};

pfm.species_to_code_map = {};

pfm.boat_to_code_map = {};

pfm.getSpeciesInfo = function (species) {

    var speciesTokens = (species ? species.trim().match(/\S+/g) : null);

    if (!speciesTokens || !speciesTokens.length) {
        speciesTokens = ['None'];
    }

    var speciesCode = speciesTokens[0].toUpperCase();

    return (pfm.speciesMap[speciesCode] ? pfm.speciesMap[speciesCode] : pfm.speciesMap['NONE']);
};

/*
pfm.dump_bounds = function (label) {

    label = (label ? label : "");

    var m = pfm.map;

    console.log("MIN ZOOM: " + m.getMinZoom());
    console.log("CUR ZOOM: " + m.getZoom());
    console.log("MAX ZOOM: " + m.getMaxZoom());

    var bounds = m.getBounds();

    var center = bounds.getCenter();

    var nw = bounds.getNorthWest();
    var ne = bounds.getNorthEast();
    var se = bounds.getSouthEast();
    var sw = bounds.getSouthWest();

    var w = nw.distanceTo(ne);
    var h = nw.distanceTo(sw);

    var w_mi = w / 1609.34;
    var h_mi = h / 1609.34;

    console.log(label + " center: " + center.toString());
    console.log("nw:" + nw + " / ne:" + ne + " / se:" + se + " / sw:" + sw);

    console.log(label + " width (m): " + w);
    console.log(label + " width (mi): " + w_mi);
    console.log(label + " height (m): " + h);
    console.log(label + " height (mi): " + h_mi);

    console.log(label + "SqMi : " + (w_mi * h_mi));

};
*/

pfm.handleMarkerMouseEvent = function (e, m) {

    if (e.type === 'click') {
        if ((pfm.options.USER_CAN_HOVER && !pfm.options.openPopupOnHover) ||
            !pfm.options.USER_CAN_HOVER) {
            if (m.getPopup().isOpen()) {
                m.closePopup();
            } else {
                m.openPopup();
            }
        }
    } else if (e.type === 'mouseover') {
        pfm.options.USER_CAN_HOVER = true;
        if (pfm.options.openPopupOnHover) m.openPopup();
    } else if (e.type === 'mouseout') {
        if (pfm.options.USER_CAN_HOVER && pfm.options.openPopupOnHover) m.closePopup();
    }
    return;
};

pfm.build_features_from_sighting_data = function (sightings) {

    var feature_group = [];

    function buildMarker(latToDisplay, longToDisplay, marker, info, sighting) {

        //console.log("ADD: " + latToDisplay + "/" + longToDisplay + " :: " + JSON.stringify(sighting, null, 2));

        if (marker == null) {
            //console.log("ADD: " + JSON.stringify(sighting, null, 2));
            //console.log("Bad Lat/Long: " + [sighting.latitude, sighting.longitude]);
            return null;
        }

        var imgUrl = pfm.pwfOpts.imgurl + '/' + info.image;

        var sightingImageHtml = (info.image ?
            '<td class="sightingImageDisplay" colspan="2"><img class="sightingImage" src="' + imgUrl + '"></img></td></tr>' :
            '<td></td>');

        var sd = new Date(sighting.sighting_date_and_time + 'Z');
        var fsd = moment(sd).format('MMM DD YYYY, HH:mm ');
        var tzName = moment.tz.guess();
        var abbr = moment.tz(tzName).zoneAbbr();
        fsd += abbr;

        var marker_text = '<table class=\'popup\'><tbody>' +
            '<tr>' + sightingImageHtml + '</tr>' +
            '<tr><th>Species:</th><td>' + info.name + ' (' + info.key + ')</td></tr>' +
            '<tr><th>Date Sighted:</th><td>' + fsd + '</td></tr>' +
            '<tr><th>Boat Name:</th><td>' + sighting.boat_name + '</td></tr>' +
            '<tr><th>Latitude:</th><td>' + latToDisplay + '</td></tr>' +
            '<tr><th>Longitude:</th><td>' + longToDisplay + '</td></tr>' +
            '</tbody></table>';

        var opts = { autoPan: true, closeButton: false, maxWidth: 400 };
        var popup = L.popup(opts).setContent(marker_text);

        marker.bindPopup(popup);

        marker.on('mouseover', function (e) { pfm.handleMarkerMouseEvent(e, marker); });
        marker.on('mouseout', function (e) { pfm.handleMarkerMouseEvent(e, marker); });
        marker.off('click');
        marker.on('click', function (e) { pfm.handleMarkerMouseEvent(e, marker); });

        return marker;
    }

    function addSighting(sighting) {

        var info = pfm.getSpeciesInfo(sighting.species);

        //if(info.key=="NONE") return;
        if (!info.show) return;

        var icon = new SmallIcon({
            iconUrl: pfm.pwfOpts.iconurl + '/' + info.icon,
            iconRetinaUrl: pfm.pwfOpts.iconurl + '/' + info.icon2,
            //shadowUrl      : pfm.pwfOpts.iconurl + '/' + 'marker-shadow.png',
        });

        var boat = parseCodeTabValue(sighting.boat_name);

        if (!boat) {
            //console.log("BAD SIGHTING: " + JSON.stringify(sighting, null, 2));
            return;
        }

        //console.log("BOAT: " + JSON.stringify(boat, null, 2));
        var boatInfo = pfm.boatMap[boat.code];
        //console.log("INFO: " + JSON.stringify(boatInfo, null, 2));

        if (boatInfo && !boatInfo.show) return;

        var marker = null;

        var latLng = [Number(sighting.latitude), Number(sighting.longitude)];

        if (latLng[0] <= 0.0001 && latLng[1] <= 0.0001) {
            //console.log("ADD: " + JSON.stringify(sighting, null, 2));
            //console.log("Bad Lat/Long: " + [sighting.latitude, sighting.longitude]);
            return;
        }

        var latToDisplay = latLng[0];
        var longToDisplay = latLng[1];
        if (latLng[1] <= 0) {
            //latLng[1] += 360.0;
        }

        try {
            marker = L.marker(latLng, { icon: icon });
        } catch (e) {
            //console.log("ADD: " + JSON.stringify(sighting, null, 2));
            //console.log("Bad Lat/Long: " + [sighting.latitude, sighting.longitude]);
            latLng = [Number(sighting.location_latitude), Number(sighting.location_longitude)];
            latToDisplay = latLng[0];
            longToDisplay = latLng[1];
            if (latLng[1] <= 0) {
                //latLng[1] += 360.0;
            }
            marker = L.marker(latLng, { icon: icon });
        }

        if (marker == null) {
            //console.log("ADD: " + JSON.stringify(sighting, null, 2));
            //console.log("Bad Lat/Long: " + [sighting.latitude, sighting.longitude]);
            return;
        }

        marker = buildMarker(latToDisplay, longToDisplay, marker, info, sighting);
        if (marker) {
            feature_group.push(marker);

            /*
            try {
                var newLongToDisplay = Number(longToDisplay);
                if(newLongToDisplay<0) {
                    newLongToDisplay += 360;
                } else if(newLongToDisplay>=0) {
                    newLongToDisplay -= 360;
                }
                latLng = [latToDisplay, newLongToDisplay];
                marker = L.marker(latLng, {icon: icon});
                marker = buildMarker(latToDisplay, longToDisplay, marker, info, sighting);
                if(marker) {
                    feature_group.push(marker);
                }
                                
            } catch (e) {
                console.log("ERROR: " + e.toString());
            }
            */
        }

    }

    for (var i = 0; i < sightings.length; i++) {
        addSighting(sightings[i]);
    }

    return { feature_group: feature_group };
};



function attachModalListeners(modalElm) {
    modalElm.querySelector('.close_modal').addEventListener('click', toggleModal);
    modalElm.querySelector('.overlay').addEventListener('click', toggleModal);

    modalElm.querySelector('.close_modal').addEventListener('keydown', toggleModal);
    modalElm.querySelector('.close_modal').focus();
}

function detachModalListeners(modalElm) {
    modalElm.querySelector('.close_modal').removeEventListener('click', toggleModal);
    modalElm.querySelector('.overlay').removeEventListener('click', toggleModal);

    modalElm.querySelector('.close_modal').removeEventListener('keydown', toggleModal);
}


function toggleModal() {

    if (!pfm.modal) return;

    var currentState = pfm.modal.style.display;

    // If modal is visible, hide it. Else, display it.
    if (currentState === 'none') {
        pfm.modal.style.display = 'block';
        attachModalListeners(pfm.modal);
    } else {
        pfm.modal.style.display = 'none';
        detachModalListeners(pfm.modal);
    }
}

pfm.hide_err = function () {
    document.getElementById('errmsg').textContent = '';
    if (pfm.modal) {
        pfm.modal.style.display = 'none';
        detachModalListeners(pfm.modal);
    }
};

pfm.show_err = function (err) {
    document.getElementById('errmsg').textContent = err;
    if (pfm.modal) {
        pfm.modal.style.display = 'block';
        pfm.modal.style['z-index'] = 9000;
        attachModalListeners(pfm.modal);
    }
};

pfm.setupDatePickers = function (target_map) {

    L.control.custom({
        position: 'bottomright',
        content: '' +
            '<div class="input-group">' +
            '    <input type="text" id="startdatepicker" class="form-control input-sm" ' +
            '                 placeholder="Start Date">' +
            '    <input type="text" id="enddatepicker" class="form-control input-sm" ' +
            '                 placeholder="End Date">' +
            '    <span class="input-group-btn">' +
            '        <button id="setdates" class="btn btn-default btn-sm" type="button">Set Date Range</button>' +
            '    </span>' +
            '</div>',
        classes: '',
    }).addTo(target_map);

    document.querySelector('#startdatepicker').addEventListener('click', function () {
        pfm.hide_err();
    });

    document.querySelector('#enddatepicker').addEventListener('click', function () {
        pfm.hide_err();
    });

    pfm.startpicker = new Pikaday({ field: document.getElementById('startdatepicker') });
    pfm.endpicker = new Pikaday({ field: document.getElementById('enddatepicker') });

    document.querySelector('#setdates').addEventListener('click', function () {
        pfm.hide_err();
        pfm.reload_values();
    });

    let start = new Date();
    start.setDate(start.getDate() - 7);
    let end = new Date();

    pfm.startpicker.setDate(start);
    pfm.endpicker.setDate(end);

    let modalHtml = `<div id="errorModal" class="pwfmodal">
        <div class="overlay"></div>
        <div class="modal_content">
            <h2>Error</h2>
            <p style="text-align: center;" id="errmsg"></p>
            <button title="Close" class="close_modal">
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>`;

    let modal = createElementFromHTML(modalHtml);

    document.querySelector('#pwfmap').appendChild(modal);

    pfm.modal = document.querySelector('#errorModal.pwfmodal');

    pfm.modal.style.display = 'none';


};

pfm.reload_values = async function () {

    //console.log("RELOAD");

    pfm.hide_err();

    var sightingsUrl = pfm.pwfOpts.sightingsServiceURL;

    var valuesUrl = sightingsUrl.replace(/get_sightings.php/, 'get_allowable_values.php');

    fetch(valuesUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'text/plain'
        },
        credentials: 'include',
    }).then(async function (response) {
        if (response.ok) {
            let rsp = await response.json();
            return pfm.handle_new_values(rsp);
        } else {
            var err = new Error(response.statusText);
            err.response = response;
            throw err;
        }
        //response.status     //=> number 100–599
        //response.statusText //=> String
        //response.headers    //=> Headers
        //response.url        //=> String
        //return response.text()
    }, function (error) {
        var err = new Error(error.message);
        err.error = error;
        throw err;
    });

};

pfm.reload_sightings = async function () {

    let sightingsUrl = pfm.pwfOpts.sightingsServiceURL;
    let start = pfm.startpicker.getDate();
    let end = pfm.endpicker.getDate();

    if (!start || !end) {

        if (!start && !end) {
            end = new Date();
            start = new Date();
            start.setDate(end.getDate() - 7);
        } else if (start && !end) {
            end = new Date();
            end.setDate(start.getDate() + 7);
        } else if (!start && end) {
            start = new Date();
            start.setDate(end.getDate() - 7);
        }

        pfm.startpicker.setDate(start);
        pfm.endpicker.setDate(end);

    }

    fetch(sightingsUrl + '?start_date=' + start.toISOString() + '&end_date=' + end.toISOString(), {
        method: 'GET',
        headers: {
            'Content-Type': 'text/plain'
        },
        credentials: 'include'
    }).then(async function (response) {
        if (response.ok) {
            let rsp = await response.json();
            pfm.handle_new_sightings(rsp);
            pfm.getFeaturesInView();
        } else {
            var err = new Error(response.statusText);
            err.response = response;
            throw err;
        }
        //response.status     //=> number 100–599
        //response.statusText //=> String
        //response.headers    //=> Headers
        //response.url        //=> String
        //return response.text()
    }, function (error) {
        var err = new Error(error.message);
        err.error = error;
        throw err;
    });

};

pfm.cached_results = null;


pfm.getFeaturesInView = () => {
    const map = pfm.pwfmap;
    let features = [];
    if(!map) return features;
    let min_lat = 90;
    let max_lat = -90;
    let min_lon = 180;
    let max_lon = -180;
    map.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
            const ll = layer.getLatLng();
            if(ll.lat<min_lat) min_lat = ll.lat;
            if(ll.lat>max_lat) max_lat = ll.lat;
            if(ll.lng<min_lon) min_lon = ll.lng;
            if(ll.lng>max_lon) max_lon = ll.lng;
            if (map.getBounds().contains(layer.getLatLng())) {
                features.push(layer.feature);
            }
        }
    });
    if(features.length === 0) {
        alert('There have been no sightings within the past 7 days in your area. The map will zoom out to show all current sightings.');
        let corner1 = L.latLng(min_lat, min_lon);
        let corner2 = L.latLng(max_lat, max_lon);
        let bounds = L.latLngBounds(corner1, corner2);
        pfm.pwfmap.fitBounds(bounds);
    }
    return features;
};

pfm.handle_new_sightings = function (results) {

    if (!results) {
        if (!pfm.cached_results) {
            results = { error: 'No new sightings are available.' };
        } else {
            results = pfm.cached_results;
        }
    }

    if (results.error) {
        pfm.show_err(results.error);
    } else {

        pfm.cached_results = results;

        var features = pfm.build_features_from_sighting_data(results.sightings);

        pfm.sightings.clearLayers();

        for (let feature in features.feature_group) {
            pfm.sightings.addLayer(features.feature_group[feature]);
        }


    }

};



function parseCodeTabValue(species) {

    if (!species) return null;

    // The horror begins. This is *one of*:
    // 1) a tab-separated string w/ species code then species description, or
    // 2) a single word (e.g., "None"), or
    // 3) a space-separated string, the first word of which is all capitals and is the species code, or
    // 4) something unknown, which we will simply ignore.

    var trimmed = species.trim();
    var containsWhitespace = /\s/g.test(trimmed);

    let s_code = null;
    let s_value = null;

    if (trimmed.indexOf('\t') >= 0) {
        let codeAndSpecies = trimmed.split('\t');
        //console.log("TAB: " + codeAndSpecies[0] + "::" + codeAndSpecies[1]);
        s_code = codeAndSpecies[0];
        s_value = codeAndSpecies[1];
    } else if (trimmed.length > 0 && !containsWhitespace) {
        //console.log("SNG: " + trimmed.toUpperCase() + "::" + trimmed);
        s_code = trimmed.toUpperCase();
        s_value = trimmed;
    } else if (containsWhitespace) {
        let codeAndSpecies = trimmed.split(/\s/);
        let cas_code = codeAndSpecies[0];
        let cas_species = '';
        for (var i = 1; i < codeAndSpecies.length; ++i) {
            cas_species += codeAndSpecies[i];
            if (i < (codeAndSpecies.length - 1)) cas_species += ' ';
        }
        //console.log("WTS: " + cas_code.toUpperCase() + "::" + cas_species.trim());
        s_code = cas_code.toUpperCase();
        s_value = cas_species;
    } else {
        // NOOP -- bad data, we ignore
        //console.log("BAD: " + trimmed);
        return null;
    }

    if (s_code.toUpperCase() === 'OD') {
        if (s_value.toLocaleLowerCase().indexOf('disc') >= 0) {
            // This is the Ocean Discovery
            // NOOP
        } else if (s_value.toLocaleLowerCase().indexOf('defend') >= 0) {
            // This is the Ocean Defender
            // Code for Ocean Defender will need to be "ODEF"
            s_code = 'ODEF';
        }
    }
    return { code: s_code, val: s_value };
}

pfm.handle_new_values = function (results) {
    if (results.fields && results.fields.length > 0) {

        // ***********************************************************************
        // SPECIES FILTER SETUP

        var species_codes = results.fields.find(function (obj) {
            return obj.field_name === 'field_species_code';
        });
        if (species_codes) {
            species_codes = species_codes.allowed_values;

            let initial_list = ['', '', '', '', ''];
            let full_list = [];

            for (var species in species_codes) {
                let parsed = parseCodeTabValue(species_codes[species]);
                // The species list should be displayed
                // alphabetically after the following 5 species:
                // humpback whale, bottlenose dolphin, spinner
                // dolphin, spotted dolphin, and false killer
                // whale. The default map should have all species
                // selected.
                if (parsed && parsed.val) {
                    var s_code = parsed.code;
                    var s_val = parsed.val;
                    pfm.species_to_code_map[s_val] = s_code;
                    if (s_code === 'HBW') {
                        initial_list[0] = s_val;
                    } else if (s_code === 'BND') {
                        initial_list[1] = s_val;
                    } else if (s_code === 'SPN') {
                        initial_list[2] = s_val;
                    } else if (s_code === 'SPT') {
                        initial_list[3] = s_val;
                    } else if (s_code === 'FKW') {
                        initial_list[4] = s_val;
                    } else {
                        full_list.push(s_val);
                    }
                }
            }

            full_list.sort();

            var species_list = initial_list.concat(full_list);

            pfm.species_list = species_list;

            for (let species in species_list) {
                (function (species_name) {
                    var s_code = pfm.species_to_code_map[species_name];
                    var entry = pfm.speciesMap[s_code];
                    if (!entry.added) {
                        pfm.pwfsidebar.addSpecies(species_name,
                            'boolean',
                            true,
                            function (evt, input) {
                                if (entry) {
                                    entry.show = input.checked;
                                }
                                pfm.handle_new_sightings(null);
                            });
                        entry.added = true;
                    }
                })(species_list[species]);
            }

            pfm.pwfsidebar.setupSpeciesExpander();

        }

        // ***********************************************************************
        // BOAT NAME FILTER SETUP

        var boat_codes = results.fields.find(function (obj) {
            return obj.field_name === 'field_boat_name';
        });
        if (boat_codes) {
            boat_codes = boat_codes.allowed_values;

            let initial_list = ['', '', '', '', ''];
            let full_list = [];

            for (var vessel in boat_codes) {
                let parsed = parseCodeTabValue(boat_codes[vessel]);
                // The vessel list should be displayed
                // alphabetically after the following 5 vessels:
                // Ocean Discovery, Ocean Explorer, Ocean Odyssey,
                // Ocean Quest, and Ocean Spirit. The default list
                // should have all vessels selected.

                // Compare value is: boat_name

                if (parsed && parsed.val) {
                    let s_code = parsed.code;
                    let s_val = parsed.val;

                    if (s_val.toLocaleLowerCase().indexOf('select') > 0) continue;

                    pfm.boat_to_code_map[s_val] = s_code;

                    if (s_code === 'ODISC') {
                        initial_list[0] = s_val;
                    } else if (s_code === 'OEXP') {
                        initial_list[1] = s_val;
                    } else if (s_code === 'OODYS') {
                        initial_list[2] = s_val;
                    } else if (s_code === 'OQST') {
                        initial_list[3] = s_val;
                    } else if (s_code === 'OSPRT') {
                        initial_list[4] = s_val;
                    } else {
                        full_list.push(s_val);
                    }
                }
            }

            full_list.sort();

            var boat_list = initial_list.concat(full_list);

            pfm.boat_list = boat_list;

            for (var boat in boat_list) {
                (function (boat_name) {
                    var s_code = pfm.boat_to_code_map[boat_name];
                    var entry = pfm.boatMap[s_code];
                    if (!entry || !entry.added) {

                        entry = { key: s_code, name: boat_name, show: true };

                        pfm.boatMap[s_code] = entry;

                        pfm.pwfsidebar.addBoat(boat_name,
                            'boolean',
                            true,
                            function (evt, input) {
                                if (entry) {
                                    entry.show = input.checked;
                                }
                                pfm.handle_new_sightings(null);
                            });
                        entry.added = true;
                    }
                })(boat_list[boat]);
            }

            pfm.pwfsidebar.setupBoatListExpander();

        }

        pfm.pwfsidebar.collapse();

        pfm.reload_sightings();

    } else {
        //console.log(results);
    }
};

pfm.setup_and_load = function (options) {

    pfm.boatMap = {};

    pfm.speciesMap = pfm_species_map;

    let _m = pfm.speciesMap;
    for(let _s_key in _m) {
        if(_m.hasOwnProperty(_s_key)) {
            var entry = _m[_s_key];
            entry.added = false;
        }
    }

    let mapOpts = {
        ...options,
        attributionControl: false,
        zoomControl: false,
        //scrollWheelZoom: 'center'
    };

    let pwfOpts = {};
    pwfOpts.iconurl = 'icon';
    pwfOpts.imgurl = 'img';
    pwfOpts.baseLayers = [];

    pwfOpts.baseLayers.push({
        name: 'OpenMapSurfer_Roads',
        layer: L.tileLayer('http://korona.geog.uni-heidelberg.de/tiles/roads/x={x}&y={y}&z={z}',
            {
                maxZoom: 20,
                attribution: 'Imagery from <a href="http://giscience.uni-hd.de/">GIScience Research Group @ University of Heidelberg</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            })
    });

    pwfOpts.baseLayers.push({
        name: 'OpenTopoMap',
        layer: L.tileLayer.provider('OpenTopoMap')
    });

    pwfOpts.baseLayers.push({
        name: 'Esri.OceanBasemap',
        layer: L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/Ocean/World_Ocean_Base/MapServer/tile/{z}/{y}/{x}',
            {
                attribution: 'Tiles &copy; Esri &mdash; Sources: GEBCO, NOAA, CHS, OSU, UNH, CSUMB, National Geographic, DeLorme, NAVTEQ, and Esri',
                maxZoom: 13
            }
        )
    });

    pwfOpts.baseLayers.push({
        name: 'MapBox',
        layer: L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/outdoors-v10/tiles/256/{z}/{x}/{y}?access_token={accessToken}',
            {
                attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>',
                maxZoom: 20,
                id: 'PWFtoken1',
                accessToken: 'pk.eyJ1IjoicHdmIiwiYSI6ImNqMWg1MXRxazAwYm0yeHBtcHUwM2FqejEifQ.qHFVZQ0f3foJVJdOVWB6LA'
            })
    });

    pwfOpts.baseLayers.push({
        name: 'MapBox Satellite',
        layer: L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v10/tiles/256/{z}/{x}/{y}?access_token={accessToken}',
            {
                attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>',
                maxZoom: 20,
                id: 'PWFtoken1',
                accessToken: 'pk.eyJ1IjoicHdmIiwiYSI6ImNqMWg1MXRxazAwYm0yeHBtcHUwM2FqejEifQ.qHFVZQ0f3foJVJdOVWB6LA'
            })
    });

    pwfOpts.defaultLayer = 'Esri.OceanBasemap';

    if (process && process.env && (process.env.REACT_APP_API_SERVER || process.env.REACT_APP_ROOT_PATH)) {
        const raw_api_server = process.env.REACT_APP_API_SERVER ? process.env.REACT_APP_API_SERVER : '/';
        const api_server = raw_api_server.endsWith('/') ? raw_api_server.substr(0, raw_api_server.length - 1) : raw_api_server;
        pwfOpts.iconurl = api_server + '/icon';
        pwfOpts.imgurl = api_server + '/img';
    }

    pwfOpts.sightingsServiceURL = 'https://log.pacificwhale.org/sites/log.pacificwhale.org/modules/sightings_service/get_sightings.php';

    pfm.mapOpts = mapOpts;
    pfm.pwfOpts = pwfOpts;

    // create map
    let pwfmap = L.map('pwfmap', pfm.mapOpts);

    var baseMaps = {};

    var baseLayers = pfm.pwfOpts.baseLayers;

    for (var idx in baseLayers) {
        var l = baseLayers[idx];
        baseMaps[l.name] = l.layer;
        if (l.name === pfm.pwfOpts.defaultLayer) {
            l.layer.addTo(pwfmap);
        }

    }

    pwfmap.addControl(new L.Control.Fullscreen({ position: 'topright' }));

    L.control.scale({ position: 'bottomleft' }).addTo(pwfmap);
    L.control.zoom({ position: 'topleft' }).addTo(pwfmap);
    L.control.attribution({ prefix: false }).addTo(pwfmap);

    pwfmap.setView(pfm.mapOpts.center, pfm.mapOpts.zoom);

    pfm.pwfmap = pwfmap;

    pfm.sightings = L.layerGroup([]);

    let overlayMaps = {
        'Sightings': pfm.sightings
    };

    pfm.sightings.addTo(pwfmap);

    pfm.pwfsidebar = L.control.pwfsidebar(baseMaps, overlayMaps).addTo(pwfmap);
    pfm.pwfsidebar.addOption('Open popup on hover?', 'boolean', pfm.options.openPopupOnHover,
        function (evt, input) {
            pfm.options.openPopupOnHover = input.checked;
        });

    window.pfm = pfm;

    pfm.setupDatePickers(pwfmap);

    pfm.reload_values();

};