import React, { useState } from 'react';
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import { formatSightingDateTime } from '../../utils/_pfm-utils';
const { Set } = require('immutable');

export function FieldDecode(props) {
  let schema_field = props.schema_field;
  let field_contents = props.field_contents || null;

  if (schema_field.allowed_values) {
    if (Array.isArray(field_contents)) {
      // eslint-disable-next-line
      field_contents = field_contents.map(fc => schema_field.allowed_values.find(av => av.key == fc));
      field_contents = (
        <ul>
          {field_contents.map(fc => (
            <li key={fc.key}>{fc.name ? fc.name : fc.value}</li>
          ))}
        </ul>
      );
    } else {
      field_contents = schema_field.allowed_values.find(entry => entry.key === field_contents);
      field_contents = field_contents ? <span>{field_contents.name ? field_contents.name : field_contents.value}</span> : <span />;
    }
  }

  if (schema_field.type === 'datetime') {
    field_contents = formatSightingDateTime(field_contents);
  }

  return field_contents;
}

// Fetch appropriate control for a field

// Allowed types are:
// select  [dropdown]
// number  [text input accepting a number]
// datetime  [date and time]
// multiselect  [checkboxes]
// text   [text input]
// multitext  [multi-line text input]

export function validateField(schema_field, field_contents) {
  let err = null;

  if (schema_field.disabled) {
    return err;
  }

  let is_empty = true;

  switch (schema_field.type) {
    case 'select':
      is_empty = !field_contents;
      break;
    case 'number':
      is_empty = field_contents !== 0 && !field_contents;
      if (!is_empty) {
        if (isNaN(Number(field_contents))) {
          err = {};
          err.field_name = schema_field.field_name;
          err.error = schema_field.label + ' must be a number';
        }
      }
      break;
    case 'datetime':
      is_empty = !field_contents;
      break;
    case 'multiselect':
      if (!field_contents) {
        is_empty = true;
      } else if (Array.isArray(field_contents)) {
        if (field_contents.length) is_empty = false;
      }
      break;
    case 'text':
      is_empty = !field_contents;
      break;
    case 'multitext':
      is_empty = !field_contents;
      break;
    default:
      break;
  }

  if (!err && schema_field.required && is_empty) {
    err = {};
    err.field_name = schema_field.field_name;
    err.error = schema_field.label + ' is required';
  }

  return err;
}

export function FieldControl({ schema_field, field_contents, change_handler, blur_handler, error_message }) {
 
  let [value, set_value] = useState(field_contents);

  if (schema_field.disabled) return null;

  let input_control = <span />;

  // Check if we've changed...

  if (Array.isArray(field_contents)) {
    let old_fc = Set(value);
    let new_fc = Set(field_contents);
    if (!old_fc.equals(new_fc)) {
      if (window._do_pwf_debug_log) console.log('CHANGE DETECTED');
      if (window._do_pwf_debug_log) console.log(schema_field.field_name);
      if (window._do_pwf_debug_log) console.log(old_fc.toJSON());
      if (window._do_pwf_debug_log) console.log(new_fc.toJSON());
      set_value(field_contents);
    } else {
      const err = validateField(schema_field, value);
      error_message = error_message ? error_message : err ? err.error : null;
    }
  } else {
    // eslint-disable-next-line
    if (value != field_contents) {
      set_value(field_contents);
    } else {
      const err = validateField(schema_field, value);
      error_message = error_message ? error_message : err ? err.error : null;
    }
  }

  switch (schema_field.type) {
    case 'select':
      const blank_entry = [<option key={'_DEFAULT_BLANK_ENTRY'} value={''} />];
      const entries = schema_field.allowed_values.map(elt => {
        return elt.disabled ? null : (
          <option key={elt.key} value={elt.key}>
            {elt.name ? elt.name : elt.value}
          </option>
        );
      });
      input_control = (
        <div className="select">
          <select id={schema_field.field_name} defaultValue={field_contents} onChange={change_handler} onBlur={blur_handler}>
            {blank_entry.concat(entries)}
          </select>
        </div>
      );
      break;
    case 'number':
      if (schema_field.type === 'number') {
        // Special case handling for fields of type number...
        if (typeof field_contents === 'string' && field_contents !== '') {
          // Do not convert empty strings to 0
          const _as_num = Number(field_contents);
          if (!isNaN(_as_num)) {
            if (window._do_pwf_debug_log) console.log('Converting to number: ' + _as_num);
            field_contents = _as_num;
          }
        }
      }
      input_control = (
        <input
          id={schema_field.field_name}
          className="input"
          //type="number"
          data-is-number-field={true}
          defaultValue={field_contents}
          onChange={change_handler}
          onBlur={blur_handler}
        />
      );
      break;
    case 'datetime':
      input_control = (
        <Flatpickr
          id={schema_field.field_name}
          data-enable-time
          data-is-date-time-picker
          className="input"
          value={field_contents}
          onChange={(selectedDates, dateStr, instance) => change_handler(instance._input)}
          onBlur={blur_handler}
        />
      );
      break;
    case 'multiselect':
      const decoded_values = field_contents;
      input_control = (
        <div className="multiselect">
          {schema_field.allowed_values.map(av => {
            if (av.disabled) return null;

            let is_checked = false;
            if (Array.isArray(decoded_values)) {
              is_checked = !!decoded_values.find(function(checked_value) {
                return checked_value === av.key;
              });
            } else {
              // eslint-disable-next-line
              is_checked = !!(decoded_values == av.key);
            }

            return (
              <div key={av.key}>
                <label className="checkbox">
                  <input
                    id={schema_field.field_name + '.' + av.key}
                    data-is-multi-select={true}
                    data-multi-select-field={schema_field.field_name}
                    data-multi-select-key={av.key}
                    type="checkbox"
                    defaultChecked={is_checked}
                    onChange={change_handler}
                    onBlur={blur_handler}
                  />
                  {av.name ? av.name : av.value}
                </label>
              </div>
            );
          })}
        </div>
      );
      break;
    case 'text':
      input_control = (
        <input
          id={schema_field.field_name}
          className="input"
          type="text"
          defaultValue={field_contents}
          onChange={change_handler}
          onBlur={blur_handler}
        />
      );
      break;
    case 'multitext':
      input_control = (
        <textarea
          id={schema_field.field_name}
          className="textarea"
          defaultValue={field_contents}
          onChange={change_handler}
          onBlur={blur_handler}
        />
      );
      break;
    default:
      break;
  }

  return (
    <div className="control">
      {input_control}
      <div className="has-text-danger">{error_message}</div>
    </div>
  );
}
