import 'whatwg-fetch';
import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';

import history from './utils/history';
import { rootpath } from './utils/rootpath';

import './utils/global-setup';

import { ToastContainer, toast } from 'react-toastify';
import { MiniRouter } from './utils/minirouter';

import { NavBar } from './components/navbar/navbar';
import { LoginForm } from './components/login/loginform';
import { PasswordReset } from './components/password_reset/password_reset';
import { PasswordResetWithToken } from './components/password_reset/password_reset with_token';
import { WhaleMap } from './components/map/whalemap';

import { UserTable } from './components/users/usertable';
// PRE-HOOKS

import { UserAddOrEditPage } from './components/users/editpage';

import { SightingsPage } from './components/sightings/sightings_page';
import { SightingViewPage } from './components/sightings/view_sighting';
import { SightingEditPage } from './components/sightings/edit_sighting';

import { TracksPage } from './components/tracks/tracks_page';
import { TrackViewPage } from './components/tracks/view_track';

import { EditSchema } from './components/schema/schema';

import { Tips, EditTips } from './components/tips_and_about/tips';
import { About, EditAbout } from './components/tips_and_about/about';

// import 'react-toastify/dist/ReactToastify.css';
// minified version is also included
import 'react-toastify/dist/ReactToastify.min.css';
import './App.scss'; //placeholder

function replaceErrors(key, value) {
  if (value instanceof Error) {
    var error = {};

    Object.getOwnPropertyNames(value).forEach(function(key) {
      error[key] = value[key];
    });

    return error;
  }

  return value;
}

export default function App() {
  // the state is only created the first time our component renders.
  // During the next renders, useState gives us the current state.

  const [currentPathname, setCurrentPathname] = useState(history.location.pathname);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [currentErrorMessage, setCurrentErrorMessage] = useState(null);
  const [hasFetched, setHasFetched] = useState(false);

  const liu = useCallback(() => JSON.stringify(loggedInUser), [loggedInUser]);
  window.getLoggedInUser = liu;

  useEffect(() => {
    const unlisten = history.listen(loc => {
      setCurrentPathname(loc.pathname);
    });
    return unlisten;
  });

  const networkRequestWrapper = async function(url, options) {
    try {
      let opts = {
        credentials: 'include',
        method: 'GET',
        ...options
      };

      const rawResponse = await fetch(url, opts);

      if (window._do_pwf_debug_log) console.log(rawResponse);

      const rtype = rawResponse.headers.get('Content-Type');

      if (window._do_pwf_debug_log) console.log(rtype);

      let rvalue = {};

      if (rtype.indexOf('text/html') >= 0) {
        rvalue = '';
        try {
          rvalue = await rawResponse.text();
        } catch (e) {
          rvalue = '';
        }
      } else if (rtype.indexOf('application/json') >= 0) {
        try {
          rvalue = await rawResponse.json();
        } catch (e) {
          rvalue = {};
        }
      }

      let resp = {
        ok: rawResponse.ok,
        status_code: rawResponse.status,
        status_text: rawResponse.statusText,
        returned_body: rvalue,
        raw_response: rawResponse
      };

      if (rawResponse.status === 401) {
        setLoggedInUser(null);
        return resp;
      }

      if (resp.returned_body && resp.returned_body.toast_success) {
        toast.success(resp.returned_body.toast_success);
      } else if (resp.returned_body && resp.returned_body.toast_error) {
        toast.error(resp.returned_body.toast_error);
      }

      if (rvalue && rvalue.message === 'user updated') {
        setHasFetched(false);
        setLoggedInUser(null);
      }

      return resp;
    } catch (e) {
      return {
        ok: false,
        status_code: 0,
        status_text: 'unknown error',
        returned_body: { message: e.message ? e.message : JSON.stringify(e, replaceErrors) },
        raw_error: e
      };
    }
  };

  const home_title = 'My Sightings'; // 'Home';
  const sightings_title = home_title;

  const routes = [
    { path: '/', title: home_title, breadcrumb: [], cb: () => <SightingsPage networkfn={networkRequestWrapper} /> },
    {
      path: '/sightings_list',
      title: home_title,
      breadcrumb: [],
      cb: () => <SightingsPage strip={true} networkfn={networkRequestWrapper} />
    },
    {
      path: '/reset_password',
      title: 'Reset Password',
      breadcrumb: [],
      cb: () => <PasswordReset networkfn={networkRequestWrapper} />
    },
    {
      path: '/reset_password_with_token/:token',
      title: 'Reset Password',
      breadcrumb: [],
      cb: route => <PasswordResetWithToken token={route.match.token} networkfn={networkRequestWrapper} />
    },
    { path: '/map', title: 'Sightings Map', breadcrumb: [], cb: () => <WhaleMap /> },
    { path: '/users', title: 'Users', breadcrumb: [], cb: () => <UserTable networkfn={networkRequestWrapper} /> },
    {
      path: '/tracks',
      title: 'My Tracks',
      breadcrumb: [],
      cb: () => <TracksPage networkfn={networkRequestWrapper} />
    },
    {
      path: '/add_sighting',
      title: 'Add New Sighting',
      breadcrumb: [sightings_title],
      cb: route => <SightingEditPage networkfn={networkRequestWrapper} />
    },
    {
      path: '/view_sighting/:id',
      title: 'View Sighting',
      breadcrumb: [sightings_title],
      cb: route => <SightingViewPage sighting_id={route.match.id} networkfn={networkRequestWrapper} />
    },
    {
      path: '/view_track/:id',
      title: 'View Track',
      breadcrumb: ['My Tracks'],
      cb: route => <TrackViewPage track_id={route.match.id} networkfn={networkRequestWrapper} />
    },
    {
      path: '/edit_sighting/:id',
      title: 'Edit Sighting',
      breadcrumb: [sightings_title, { title: 'View Sighting', match: 'id' }],
      cb: route => (
        <SightingEditPage
          sighting_id={route.match.id}
          on_save_success={'/view_sighting/' + route.match.id}
          networkfn={networkRequestWrapper}
        />
      )
    },
    {
      path: '/useredit/:id',
      title: 'Edit User',
      breadcrumb: ['Users'],
      cb: route => <UserAddOrEditPage id={route.match.id} requestingUser={loggedInUser} networkfn={networkRequestWrapper} />
    },
    {
      path: '/useradd',
      title: 'Add User',
      breadcrumb: ['Users'],
      cb: () => <UserAddOrEditPage id={''} requestingUser={loggedInUser} networkfn={networkRequestWrapper} />
    },
    {
      path: '/signup',
      title: 'Sign Up',
      cb: () => <UserAddOrEditPage id={''} isSignupForm={true} networkfn={networkRequestWrapper} />
    },
    {
      path: '/tips',
      title: 'Tips',
      breadcrumb: [],
      cb: () => <Tips networkfn={networkRequestWrapper} />
    },
    {
      path: '/about',
      title: 'About Whale and Dolphin Tracker',
      breadcrumb: [],
      cb: () => <About networkfn={networkRequestWrapper} />
    },
    {
      path: '/admin_tips',
      title: 'Edit Tips',
      breadcrumb: [],
      cb: () => <EditTips networkfn={networkRequestWrapper} />
    },
    {
      path: '/admin_about',
      title: 'Edit About',
      breadcrumb: [],
      cb: () => <EditAbout networkfn={networkRequestWrapper} />
    },
    {
      path: '/fields',
      title: 'Edit Fields',
      breadcrumb: [],
      cb: () => <EditSchema networkfn={networkRequestWrapper} />
    },
    {
      path: '/logout',
      cb: () => {
        fetch(process.env.REACT_APP_API_SERVER + '/logout', {
          method: 'GET',
          credentials: 'include'
        })
          .then(() => {
            setLoggedInUser(null);
            history.push({
              pathname: rootpath + `/`
            });
          })
          .catch(err => (window.location = rootpath + `/`));
          if (window._do_pwf_debug_log) console.log('logout');
        return <div>Logging out...</div>;
      }
    }
  ];

  const mini_router = new MiniRouter(routes);

  let fetchUsername = function(data) {
    setCurrentErrorMessage(null);
    setHasFetched(true);
    setLoggedInUser(data.returned_body);
  };

  let onAuthenticationError = function(data) {
    if (window._do_pwf_debug_log) console.log('onAuthenticationError: ');
    if (window._do_pwf_debug_log) console.log(data);
    setCurrentErrorMessage(data.returned_body.message ? data.returned_body.message : data.status_text);
    setHasFetched(true);
    setLoggedInUser(null);
  };

  let onLogin = async function(data) {
    if (window._do_pwf_debug_log) console.log('onLogin: ');
    if (window._do_pwf_debug_log) console.log(data);
    setCurrentErrorMessage(null);
    let rv = await networkRequestWrapper(process.env.REACT_APP_API_SERVER + '/users/', {});
    if (rv.ok) {
      fetchUsername(rv);
    } else {
      onAuthenticationError(rv);
    }
  };

  let fn_logIn = async function(username, password) {
    const data = {
      username: username,
      password: password
    };

    const opts = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };

    let rv = await networkRequestWrapper(process.env.REACT_APP_API_SERVER + '/login', opts);

    if (rv.ok) {
      onLogin(rv);
    } else {
      onAuthenticationError(rv);
    }
  };

  let initial_setup = async function() {
    if (!hasFetched && !loggedInUser) {
      let rv = await networkRequestWrapper(process.env.REACT_APP_API_SERVER + '/users/', {});
      if (rv.ok) {
        fetchUsername(rv);
      } else {
        setHasFetched(true);
      }
    }
  };

  let current_route = mini_router.evaluate(currentPathname);
  current_route = current_route && current_route.length ? current_route[0] : null;
  let requestedComponent = current_route && current_route.cb ? current_route.cb(current_route) : <div />;

  if (window._do_pwf_debug_log) console.log(current_route);

  if (current_route && current_route.path.endsWith('/sightings_list')) {
    window.mobile_app_override = true;
  }

  let breadcrumb = null;
  if (current_route && current_route.breadcrumb) {
    breadcrumb = current_route.breadcrumb.map(crumb => {
      if (typeof crumb === 'string') {
        const crumb_route = routes.find(r => r.title === crumb);
        return crumb_route ? (
          <li key={crumb_route.path}>
            <a className='app-link' href={crumb_route.path}>
              {crumb_route.title}
            </a>
          </li>
        ) : (
          <li>Unknown</li>
        );
      } else if (typeof crumb === 'object') {
        const crumb_route = routes.find(r => r.title === crumb.title);
        const match_value = current_route.match && current_route.match[crumb.match] ? current_route.match[crumb.match] : null;
        let crumb_path = crumb_route.path.replace(':' + crumb.match, match_value);

        return crumb_route ? (
          <li key={crumb_path}>
            <a className='app-link' href={crumb_path}>
              {crumb_route.title}
            </a>
          </li>
        ) : (
          <li>Unknown</li>
        );
      } else {
        return <li>Unknown</li>;
      }
    });
    breadcrumb.push(
      <li key={current_route.path} className='is-active'>
        <a className='app-link' aria-current='page' href={current_route.path}>
          {current_route.title}
        </a>
      </li>
    );
  }

  if (!hasFetched && !loggedInUser) initial_setup();

  let evaluatedComponent = (
    <React.Fragment>
      <div>Loading...</div>
    </React.Fragment>
  );

  if (hasFetched) {
    if (loggedInUser) {
      evaluatedComponent = (
        <React.Fragment>
          {window.mobile_app_override || (current_route && current_route.path.endsWith('/sightings_list')) ? null : (
            <NavBar user={loggedInUser} />
          )}

          <nav className='pwf breadcrumb is-centered' aria-label='breadcrumbs'>
            <ul>{breadcrumb}</ul>
          </nav>

          {requestedComponent}
        </React.Fragment>
      );
    } else if (current_route && current_route.path.endsWith('/signup')) {
      evaluatedComponent = requestedComponent;
    } else if (current_route && current_route.path.endsWith('/reset_password')) {
      evaluatedComponent = requestedComponent;
    } else if (current_route && current_route.path.endsWith('/reset_password_with_token/:token')) {
      evaluatedComponent = requestedComponent;
    } else {
      evaluatedComponent = <LoginForm submitfn={fn_logIn} errormsg={currentErrorMessage} />;
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <script src='https://cdnjs.cloudflare.com/ajax/libs/leaflet-gpx/1.4.0/gpx.min.js' />
      </Helmet>

      {evaluatedComponent}

      <ToastContainer className='toast-container' position='bottom-right' />
    </React.Fragment>
  );
}
