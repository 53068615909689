import React from 'react';
import { FieldDecode } from './_field_control';

export function SightingTable(props) {
  // props.schema
  // props.sighting
  // props.cached_schema_fields (optional)
  // props.display_fields (optional)
  // props.show_has_photos (optional)

  const cached_schema_fields = props.cached_schema_fields ? props.cached_schema_fields : {};
  const display_fields = props.display_fields ? props.display_fields : props.schema.fields.map(f => f.field_name);

  if (!props.cached_schema_fields) {
    for (let schema_field in props.schema.fields) {
      let field = props.schema.fields[schema_field];
      cached_schema_fields[field.field_name] = field;
    }
  }

  let track_key = props.sighting['track_key'];

  return (
    <div className='content'>
      {display_fields.map(dsp => {
        const sch_def = cached_schema_fields[dsp];
        if (!sch_def) return null;

        const field_key = props.sighting[sch_def.field_name];

        return (
          <div className='columns is-gapless' key={sch_def.field_name}>
            <div className='column has-text-weight-bold	'>{sch_def.label}</div>
            <div className='column'>{<FieldDecode schema_field={sch_def} field_contents={field_key} />}</div>
          </div>
        );
      })}

      {track_key ? (
        <div className='columns is-gapless' key={'track_key'}>
          <div className='column has-text-weight-bold	'>{'Track'}</div>
          <div className='column'><span><a href={'/view_track/'+track_key} className='app-link'>{track_key}</a></span></div>
        </div>
      ) : null}

      {props.show_has_photos ? (
        <div className='columns is-gapless'>
          <div className='column has-text-weight-bold	'>Has Photos? </div>
          <div className='column'>
            {props.sighting.sighting_photos && props.sighting.sighting_photos.length > 0 ? 'Yes' : 'No'}
          </div>
        </div>
      ) : null}
    </div>
  );
}
