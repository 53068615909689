const species_array = [
    ['ARD', 'Amazon River Dolphin'],
    ['ASD', 'Atlantic Spotted Dolphin'],
    ['AWD', 'Atlantic White-sided Dolphin'],
    ['BJI', 'Baiji'],
    ['BKW', 'Beaked Whale'],
    ['BLG', 'Beluga Whale'],
    ['BHL', 'Bhulan'],
    ['BLW', 'Blue Whale'],
    ['BND', 'Bottlenose Dolphin'],
    ['BNW', 'Bottlenose Whale'],
    ['BWW', 'Bowhead Whale'],
    ['BRW', 'Bryde\'s Whale'],
    ['BMP', 'Burmeister\'s Porpoise'],
    ['CLD', 'Chilean Dolphin'],
    ['CLY', 'Clymene Dolphin'],
    ['CMR', 'Commerson\'s Dolphin'],
    ['CMD', 'Common Dolphin'],
    ['DLP', 'Dall\'s Porpoise'],
    ['DSD', 'Dusky Dolphin'],
    ['DEB', 'Marine Debris'],
    ['DSW', 'Dwarf Sperm Whale'],
    ['FKW', 'False Killer Whale'],
    ['FNW', 'Fin Whale'],
    ['FLP', 'Finless Porpoise'],
    ['FRN', 'Franciscana'],
    ['FRD', 'Fraser\'s Dolphin'],
    ['GRW', 'Gray Whale'],
    ['HBP', 'Harbor Porpoise'],
    ['HVD', 'Heaviside\'s Dolphin'],
    ['HCD', 'Hector\'s Dolphin'],
    ['HGD', 'Hourglass Dolphin'],
    ['HBD', 'Humpback Dolphin'],
    ['HBW', 'Humpback Whale'],
    ['HMS', 'Hawaiian Monk Seal'],
    ['IRD', 'Irrawaddy Dolphin'],
    ['KLW', 'Killer Whale'],
    ['LPW', 'Long-finned Pilot Whale'],
    ['MHW', 'Melon-headed Whale'],
    ['MKW', 'Minke Whale'],
    ['NAR', 'Narwhal'],
    ['PWD', 'Pacific White-sided Dolphin'],
    ['PED', 'Peale\'s Dolphin'],
    ['PKW', 'Pygmy Killer Whale'],
    ['PRW', 'Pygmy Right Whale'],
    ['PLW', 'Pilot Whale'],
    ['PSW', 'Pygmy Sperm Whale'],
    ['RGW', 'Right Whale'],
    ['RWD', 'Right Whale Dolphin'],
    ['RIS', 'Risso\'s Dolphin'],
    ['RTD', 'Rough-toothed Dolphin'],
    ['SEW', 'Sei Whale'],
    ['SPN', 'Spinner Dolphin'],
    ['SPT', 'Spotted Dolphin'],
    ['SPP', 'Spectacled Porpoise'],
    ['SPW', 'Sperm Whale'],
    ['STR', 'Striped Dolphin'],
    ['SUS', 'Susu'],
    ['TGS', 'Tiger Shark'],
    ['TUC', 'Tucuxi'],
    ['VAQ', 'Vaquita'],
    ['WBD', 'White-beaked Dolphin'],
    ['WSH', 'Whale Shark']
];

let species_map = {
    'NONE': { key: 'NONE', name: 'None', icon: 'NONE.png', icon2: 'NONE2.png', image: null, show: true },
    'OTH': { key: 'OTH', name: 'Other or Unknown', icon: 'OTH.png', icon2: 'OTH2.png', image: null, show: true },
    'UD': { key: 'UD', name: 'Unknown Dolphin Species', icon: 'OTH.png', icon2: 'OTH2.png', image: null, show: true },
    'UW': { key: 'UW', name: 'Unknown Whale  Species', icon: 'OTH.png', icon2: 'OTH2.png', image: null, show: true }
};

function species_setup() {
    species_array.forEach(elt => {
        const s_key = elt[0];
        const s_name = elt[1];
        species_map[s_key] = {
            key: s_key,
            name: s_name,
            icon: s_key + '.png',
            icon2: s_key + '2.png',
            image: s_key + 'img.png',
            show: true
        };
    });

    return species_map;
        
}

export const pfm_species_map = species_setup();
