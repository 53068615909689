import React from 'react';

import * as Yup from 'yup'; // for everything

import history from '../../utils/history';

import logo from '../../images/pwf_logo.jpg'; // with import

const defaultUserinfo = {
  username: '',
  initialPassword: '',
  confirmedPassword: '',
  email: '',
  is_admin: false,
  is_researcher: false,
  touched: false,
  captcha: ''
};

export class UserAddOrEditPage extends React.Component {
  constructor(props) {
    super(props);
    if (window._do_pwf_debug_log) console.log(props);
    this.state = {
      ...defaultUserinfo,
      isFormValid: false,
      errors: {}
    };
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.escFunction = this.escFunction.bind(this);
    this.renderForm = this.renderForm.bind(this);

    this._fetch = this._fetch.bind(this);
    this._test_username_availability = this._test_username_availability.bind(this);
    this.testedUsernames = {};

    this.validationSchema = Yup.object().shape(
      this.props.id
        ? {
            username: Yup.string()
              .required('Username is required')
              .trim()
              .min(4, 'Username must be longer than 3 characters')
              .test('username-available', 'Username is not available', value => {
                return this._test_username_availability(value);
              }),
            email: Yup.string()
              .required('E-mail is required')
              .email('E-mail is not valid'),
            initialPassword: Yup.string()
              .test('password-min-if-exists', 'Password must be at least 6 characters', value => !value || value.length > 5)
              .equalTo(Yup.ref('confirmedPassword'), 'Passwords must match'),
            confirmedPassword: Yup.string()
              .test('password-min-if-exists', 'Password must be at least 6 characters', value => !value || value.length > 5)
              .equalTo(Yup.ref('initialPassword'), 'Passwords must match')
          }
        : {
            username: Yup.string()
              .required('Username is required')
              .trim()
              .min(4, 'Username must be longer than 3 characters')
              .test('username-available', 'Username is not available', value => {
                return this._test_username_availability(value);
              }),
            email: Yup.string()
              .required('E-mail is required')
              .email('E-mail is not valid'),
            initialPassword: Yup.string()
              .min(6, 'Password must be 6 or more characters')
              .equalTo(Yup.ref('confirmedPassword'), 'Passwords must match')
              .required('Password is required'),
            confirmedPassword: Yup.string()
              .min(6, 'Password must be 6 or more characters')
              .equalTo(Yup.ref('initialPassword'), 'Passwords must match')
              .required('Confirmation password is required')
          }
    );
  }

  _fetch = async () => {
    const rsp = await this.props.networkfn(process.env.REACT_APP_API_SERVER + '/users/id/' + this.props.id, {});
    if (rsp.ok) {
      this.testedUsernames[rsp.returned_body.username] = true;
      this.setState({
        original_username: rsp.returned_body.username,
        ...rsp.returned_body
      });
    } else {
      this.setState({ submitError: rsp.returned_body.message ? rsp.returned_body.message : rsp.status_text });
    }
  };

  escFunction(event) {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      this.handleCancelClick(event);
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  componentWillMount() {
    if (this.props.id) {
      this._fetch();
    } else {
      this.setState({ ...defaultUserinfo });
    }
  }

  componentWillReceiveProps(nextProps) {
    // or componentDidUpdate
    if (nextProps.id !== this.props.id) {
      if (this.props.id) {
        this._fetch();
      } else {
        this.setState({ ...defaultUserinfo });
      }
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Object_initializer#Computed_property_names
    this.setState({ [target.name]: value }, () => this.validateForm(target.name));
  }

  handleBlur(event) {
    const target = event.target;
    this.validateForm(target.name);
  }

  validateForm(filterFieldName) {
    //if (window._do_pwf_debug_log) console.log(filterFieldName);

    //check validity
    this.validationSchema
      .validate(this.state, {
        abortEarly: false
      })
      .then(valid => {
        this.setState({ isFormValid: true, errors: {} });
      })
      .catch(err => {
        let errors = {};
        if (err.name === 'ValidationError') {
          if (err.inner) {
            for (var key in err.inner) {
              const field = err.inner[key];
              const path = field.path;
              const message = field.message;
              /*if (filterFieldName === path)*/ errors[path] = message;
            }
          }
        }

        //  If this.state.errors has an error and the errors object does not, we remove the
        //  this.state.errors entry. If the errors object has an error for filterFieldName and
        //  this.state.errors does not, we add it.

        let tmp_errors = { ...this.state.errors };

        for (var path in tmp_errors) {
          if (tmp_errors.hasOwnProperty(path) && !errors.hasOwnProperty(path)) {
            delete tmp_errors[path];
          }
        }

        if (errors.hasOwnProperty(filterFieldName)) {
          tmp_errors[filterFieldName] = errors[filterFieldName];
        }

        this.setState({ isFormValid: false, errors: tmp_errors });
      });
  }

  _test_username_availability = async function(testUsername) {
    //if (window._do_pwf_debug_log) console.log(testUsername);
    //if (window._do_pwf_debug_log) console.log(this.state.username);

    if (!testUsername || testUsername.length < 4) return true;
    // The above is not technically true, but we want the right error message,
    // not a "username is not available" message.

    if (this.props.id && testUsername === this.state.original_username) return true;

    if (!this.testedUsernames) {
      this.testedUsernames = {};
    }

    if (this.testedUsernames.hasOwnProperty(testUsername)) {
      return this.testedUsernames[testUsername];
    } else {
      const rsp = await this.props.networkfn(process.env.REACT_APP_API_SERVER + '/users/exists/' + testUsername, {});
      if (window._do_pwf_debug_log) console.log(rsp);
      const available = rsp.status_code === 404; // available if not found, otherwise not
      this.testedUsernames[testUsername] = available;
      return available;
    }
  };

  handleSubmitClick = async function(event) {
    event.preventDefault();

    //if (window._do_pwf_debug_log) console.log('click: ' + JSON.stringify(this.state, null, 2));
    const data = this.props.isSignupForm
      ? {
          username: this.state.username,
          email: this.state.email,
          password: this.state.initialPassword,
          captcha: this.state.captcha
        }
      : {
          username: this.state.username,
          email: this.state.email,
          password: this.state.initialPassword ? this.state.initialPassword : '',
          is_admin: !!this.state.is_admin,
          is_researcher: !!this.state.is_researcher
          //timezone_name: userParam.timezone_name,
          //timezone_offset: tznum
        };

    const opts = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
      credentials: 'include'
    };

    const rsp = this.props.id
      ? await this.props.networkfn(process.env.REACT_APP_API_SERVER + '/users/id/' + this.props.id, opts)
      : this.props.isSignupForm
      ? await this.props.networkfn(process.env.REACT_APP_API_SERVER + '/users/signup', opts)
      : await this.props.networkfn(process.env.REACT_APP_API_SERVER + '/users/create', opts);

    if (rsp.ok) {
      if (window._do_pwf_debug_log) console.log('Success');
      if (window._do_pwf_debug_log) console.log(rsp);
      history.goBack();
    } else {
      if (window._do_pwf_debug_log) console.log('Error');
      if (window._do_pwf_debug_log) console.log(rsp);
      this.setState({ submitError: rsp.returned_body.message ? rsp.returned_body.message : rsp.status_text });
    }
  };

  handleCancelClick(event) {
    event.preventDefault();
    history.goBack();
  }

  renderForm() {
    return (
      <div>
        <form noValidate>
          <div className='field'>
            <label className='label'>Username</label>
            <div className='control has-icons-left has-icons-right'>
              <input
                className={`input ${this.state.errors.username ? 'is-danger' : 'is-success'}`}
                name='username'
                type='text'
                placeholder='Username'
                value={this.state.username}
                onChange={this.handleInputChange}
                onBlur={this.handleBlur}
                autoFocus
              />
              <span className='icon is-small is-left'>
                <i className='fa fa-user' />
              </span>
              <span className='icon is-small is-right'>
                <i className={`fa ${this.state.errors.username ? 'fa-exclamation-triangle' : 'fa-check'}`} />
              </span>
            </div>
            <p className={'help ' + (this.state.errors.username ? 'is-danger' : 'is-success')}>{this.state.errors.username}</p>
          </div>

          <div className='field'>
            <label className='label'>Password</label>
            <div className='control'>
              <input
                name='initialPassword'
                id='initialPassword'
                value={this.state.initialPassword}
                onChange={this.handleInputChange}
                onBlur={this.handleBlur}
                className='input'
                type='password'
                placeholder='Password'
              />
            </div>
            <p className={'help ' + (this.state.errors.initialPassword ? 'is-danger' : '')}>
              {this.state.errors.initialPassword}
            </p>

            <label className='label'>Confirm Password</label>
            <div className='control'>
              <input
                name='confirmedPassword'
                id='confirmedPassword'
                value={this.state.confirmedPassword}
                onChange={this.handleInputChange}
                onBlur={this.handleBlur}
                className='input'
                type='password'
                placeholder='Confirm Password'
              />
            </div>
            <p className={'help ' + (this.state.errors.confirmedPassword ? 'is-danger' : '')}>
              {this.state.errors.confirmedPassword}
            </p>
          </div>

          <div className='field'>
            <label className='label'>Email</label>
            <div className='control has-icons-left has-icons-right'>
              <input
                name='email'
                className={`input ${this.state.errors.email ? 'is-danger' : ''}`}
                type='email'
                placeholder='Email input'
                value={this.state.email}
                onChange={this.handleInputChange}
                onBlur={this.handleBlur}
              />
              <span className='icon is-small is-left'>
                <i className='fa fa-envelope' />
              </span>
              <span className='icon is-small is-right'>
                <i className={`fa ${this.state.errors.email ? 'fa-exclamation-triangle' : 'fa-check'}`} />
              </span>
            </div>
            <p className={`help ${this.state.errors.email ? 'is-danger' : 'is-hidden'}`}>{this.state.errors.email}</p>
          </div>

          {this.props.isSignupForm ? (
            <div className='field'>
              <label className='label'>Please Enter the Characters</label>
              <img alt='Captcha' src={process.env.REACT_APP_API_SERVER + '/captcha'} />
              <div className='control has-icons-left has-icons-right'>
                <input
                  name='captcha'
                  className={`input ${this.state.errors.captcha ? 'is-danger' : ''}`}
                  type='text'
                  placeholder='Please enter the characters above'
                  value={this.state.captcha}
                  onChange={this.handleInputChange}
                  onBlur={this.handleBlur}
                />
              </div>
              <p className={`help ${this.state.errors.captcha ? 'is-danger' : 'is-hidden'}`}>{this.state.errors.captcha}</p>
            </div>
          ) : null}

          {!this.props.requestingUser || !this.props.requestingUser.is_admin || this.props.isSignupForm ? (
            <div />
          ) : (
            <div className='level'>
              <div className='level-left'>
                <div className='field level-item'>
                  <input
                    type='checkbox'
                    name='is_admin'
                    value={this.state.is_admin ? 1 : 0}
                    onChange={this.handleInputChange}
                    onBlur={this.handleBlur}
                    checked={this.state.is_admin ? 1 : 0}
                  />
                  <label className='checkbox'>Is Administrator</label>
                </div>
              </div>

              <div className='level-right'>
                <div className='field level-item'>
                  <input
                    type='checkbox'
                    name='is_researcher'
                    value={this.state.is_researcher ? 1 : 0}
                    onChange={this.handleInputChange}
                    onBlur={this.handleBlur}
                    checked={this.state.is_researcher ? 1 : 0}
                  />
                  <label className='checkbox'>Is Research User</label>
                </div>
              </div>
            </div>
          )}

          <div className='field is-grouped sign-up'>
            <div className='control'>
              <button type="button" className='button' id='sign-up-cancel' onClick={this.handleCancelClick}>
                Cancel
              </button>
            </div>
            <div className='control'>
              <button type="submit" className='button is-link' disabled={!this.state.isFormValid} onClick={this.handleSubmitClick} autoFocus>
                Submit
              </button>
            </div>
          </div>
        </form>
        <div>
          <p className={`help ${this.state.submitError ? 'is-danger' : 'is-hidden'}`}>{this.state.submitError}</p>
        </div>
      </div>
    );
  }

  render() {
    return this.props.isSignupForm ? (
      <section className='hero is-fullheight'>
        <div className='hero-body'>
          <div className='container has-text-centered'>
            <div className='column is-6 is-offset-3'>
              <h3 className='title has-text-grey'>Sign Up</h3>
              <p className='subtitle has-text-grey'>Please enter your new account information.</p>
              <div className='box'>
                <figure className='avatar'>
                  <img alt='Logo' width='128' height='128' src={logo} />
                </figure>
                {this.renderForm()}
              </div>
            </div>
          </div>
        </div>
      </section>
    ) : (
      <section className='section'>
        <div className='is-centered columns'>
          <div className='column is-4 is-paddingless'>{this.renderForm()}</div>
        </div>
      </section>
    );
  }
}
