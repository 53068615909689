import React from 'react';

import { ConfirmDialog } from '../dialog/confirmdialog';
import { ImageViewer } from '../dialog/_oriented_image';
import bodyScroll from 'body-scroll-toggle';

const { Set } = require('immutable');

export class PhotoThumbnails extends React.Component {
  constructor(props) {
    super(props);
    if (window._do_pwf_debug_log) console.log(props);
    this.photoRef = React.createRef();
    this.state = {
      photos: [],
      photo_to_display: null,
      photo_to_delete: null,
      colWidth: 0
    };
    this._fetch = this._fetch.bind(this);
    this._fetchNew = this._fetchNew.bind(this);
    this.handleDeletePhoto = this.handleDeletePhoto.bind(this);
    this.deletePhotoFromSighting = this.deletePhotoFromSighting.bind(this);
  }

  handleDeletePhoto = async event => {
    event.preventDefault();
    this.setState({ photo_to_delete: this.state.photo_to_display, photo_to_display: null });
  };

  deletePhotoFromSighting = async () => {
    if (window._do_pwf_debug_log) console.log(this.state.photo_to_delete);

    const url = process.env.REACT_APP_API_SERVER + '/photos/id/' + this.state.photo_to_delete.photo_id;

    const rsp = await this.props.networkfn(url, { method: 'DELETE' });

    if (rsp.ok) {
      this.setState({ photo_to_delete: null });
      this._fetch();
    } else {
      if (window._do_pwf_debug_log) console.log(rsp);
      this.setState({ photo_to_delete: null });
      //this.setState({ error: rsp });
      this._fetch();
    }
    bodyScroll.enable();
  };

  _fetch = async () => {
    if (window._do_pwf_debug_log) console.log('FETCH');
    let ids = this.props.photo_ids ? this.props.photo_ids : [];
    const _photos = this.props.networkfn(process.env.REACT_APP_API_SERVER + '/photos/ids/' + JSON.stringify(ids), {});

    // Await later so that tasks can complete in parallel.
    const rsp = await _photos;

    const photos = rsp.returned_body;
    const to_display = photos && photos.length ? photos[0] : null;

    if (rsp.ok) {
      this.setState({ photos: photos, photo_to_display: to_display });
    } else {
      this.setState({ photos: [], error: rsp });
    }
  };

  _fetchNew = async nextProps => {
    let ids = nextProps.photo_ids ? nextProps.photo_ids : [];
    const _photos = nextProps.networkfn(process.env.REACT_APP_API_SERVER + '/photos/ids/' + JSON.stringify(ids), {});

    // Await later so that tasks can complete in parallel.
    const rsp = await _photos;

    const photos = rsp.returned_body;
    const to_display = photos && photos.length ? photos[0] : null;

    if (rsp.ok) {
      this.setState({ photos: photos, photo_to_display: to_display });
    } else {
      this.setState({ photos: [], error: rsp });
    }
  };

  componentDidMount() {
    this._fetch();

    let colWidth = 0;

    if (this.photoRef && this.photoRef.current) {
      const width = this.photoRef.current.getBoundingClientRect().width;
      const left_pad = window.getComputedStyle(this.photoRef.current)['padding-left'].replace('px', '');
      const right_pad = window.getComputedStyle(this.photoRef.current)['padding-right'].replace('px', '');
      colWidth = width - (parseInt(left_pad) + parseInt(right_pad));
      colWidth = Math.min(colWidth, 500);
    }

    this.setState({ colWidth: colWidth });
  }

  componentWillReceiveProps(nextProps) {
    let old_ids = this.props.photo_ids ? this.props.photo_ids : [];
    let new_ids = nextProps.photo_ids ? nextProps.photo_ids : [];

    let old_id_set = Set(old_ids);
    let new_id_set = Set(new_ids);
    if (!old_id_set.equals(new_id_set)) {
      this._fetchNew(nextProps);
    }
  }

  render() {
    let disp_photo = this.state.photo_to_display
      ? process.env.REACT_APP_API_SERVER +
        '/images/' +
        this.state.photo_to_display.creator +
        '/' +
        this.state.photo_to_display.filename
      : null;

    // https://stackoverflow.com/questions/43817118/how-to-get-the-width-of-a-react-element

    const bodyNode = (
      <div>
        <p>Are you sure you want to delete this photo?</p>
        <p className='has-text-weight-bold'>Warning: This operation cannot be undone.</p>
      </div>
    );

    const titleNode = <span>Confirm Deletion</span>;

    if (this.state.photo_to_delete) {
      bodyScroll.disable();
      return (
        <ConfirmDialog
          title={titleNode}
          body={bodyNode}
          actionName='Delete'
          onCancel={() => {
            //set_user_to_delete({});
            bodyScroll.enable();
            this.setState({ photo_to_display: this.state.photo_to_delete, photo_to_delete: null });
          }}
          onConfirm={this.deletePhotoFromSighting}
        >
          {' '}
        </ConfirmDialog>
      );
    } else {
      return (
        <div>
          <div className='columns'>
            <div ref={this.photoRef} className='column is-full thumbnails' style={{ textAlign: 'center' }}>
              {this.state.photos.map(photo => (
                <div key={photo.filename} className='has-text-centered image-holder'>
                  <img
                    onClick={e => {
                      e.preventDefault();
                      this.setState({ photo_to_display: photo });
                    }}
                    src={process.env.REACT_APP_API_SERVER + '/images/' + photo.creator + '/' + photo.filename + '?dim=150x150'}
                    alt='thumbnail'
                  />
                </div>
              ))}
            </div>
          </div>
          <div className='columns displayed-photo'>
            <div ref={this.photoRef} className='column is-full'>
              {disp_photo ? (
                <div className='button-image-overlay' style={{ maxWidth: this.state.colWidth }}>
                  <a href={disp_photo} target='_blank' rel='noopener noreferrer'>
                    <ImageViewer
                      max_height={this.state.colWidth}
                      max_width={this.state.colWidth}
                      src={disp_photo}
                      alt='sighting'
                    />
                  </a>
                  {this.props.allow_delete ? (
                    <button
                      className='button is-danger is-large is-link button-image-button'
                      title='Delete this photo'
                      onClick={this.handleDeletePhoto}
                    >
                      <i className='fa fa-trash-o' aria-hidden='true' />
                    </button>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      );
    }
  }
}
