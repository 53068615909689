import React from 'react';

import logo from '../../images/pwf_logo.jpg'; // with import

export function PasswordSuccess(props) {
  return (
    <section className='hero is-fullheight'>
      <div className='hero-body'>
        <div className='container has-text-centered'>
          <div className='column is-6 is-offset-3'>
            <h3 className='title has-text-grey'>Password Reset Request Accepted</h3>
            <p className='subtitle has-text-grey'>Next Steps</p>
            <div className='box'>
              <figure className='avatar'>
                <img alt='Logo' width='128' height='128' src={logo} />
              </figure>
              <p className='has-text-grey has-text-left' style={{ paddingBottom: '.5em' }}>
                The system will check for the existence of the email address:
              </p>
              <p className='has-text-grey has-text-centered' style={{ paddingBottom: '.5em' }}>
                <i>{props.email}</i>
              </p>
              <p className='has-text-grey has-text-left' style={{ paddingBottom: '.5em' }}>
                If the email address is found, the system will send it a message containing a password 
                reset link.
              </p>
              <p className='has-text-grey has-text-left' style={{ paddingBottom: '.5em' }}>
                Please check your email and follow the instructions contained in the message.
              </p>
              <p className='has-text-grey has-text-left'>Thank you!</p>
            </div>
            <p className='has-text-grey'>
              <a className='app-link' href='/'>
                Log In
              </a>
              &nbsp; &nbsp;
              <a className='app-link' href='/signup'>
                Sign Up
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
