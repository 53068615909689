import React, { useState, useCallback, useEffect } from 'react';

// eslint-disable-next-line
import brace from 'brace';
import AceEditor from 'react-ace';
// eslint-disable-next-line
import 'brace/mode/json';
// eslint-disable-next-line
import 'brace/theme/github';

import * as Yup from 'yup'; // for everything

const validationSchema = Yup.object().shape({
  display_fields: Yup.array().required('display_fields is required'),
  fields: Yup.array().required('fields is required')
});

export function EditSchema(props) {
  const [schema, set_schema] = useState(null);
  const [version, set_version] = useState(null);
  const [versions, set_versions] = useState(null);
  const [errors, set_errors] = useState(null);
  const [success, set_success] = useState(null);

  const _fetch = useCallback(
    async version => {
      const _schema = props.networkfn(process.env.REACT_APP_API_SERVER + '/schema' + (version ? '/' + version : ''), {});

      const returned_schema = await _schema;

      if (returned_schema.ok) {
        let obj = returned_schema.returned_body;
        set_version(obj.version);
        set_schema(JSON.stringify(obj.schema, null, 2));
        set_versions(obj.versions);
      } else {
        if (window._do_pwf_debug_log) console.log(returned_schema);
        set_schema(null);
      }
    },
    [set_schema, props]
  );

  const _handle_submit = useCallback(
    async event => {
      event.preventDefault();

      if (window._do_pwf_debug_log) console.log(schema);

      const opts = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: schema,
        credentials: 'include'
      };

      const rsp = await props.networkfn(process.env.REACT_APP_API_SERVER + '/schema', opts);

      if (rsp.ok) {
        if (window._do_pwf_debug_log) console.log('Success');
        set_success(rsp.returned_body.message);
        set_errors(null);
        if (window._do_pwf_debug_log) console.log(rsp);
        _fetch();
      } else {
        if (window._do_pwf_debug_log) console.log('Error');
        if (window._do_pwf_debug_log) console.log(rsp);
        set_success(null);
        set_errors(JSON.stringify({ submitError: rsp.returned_body.message ? rsp.returned_body.message : rsp.status_text }));
      }
    },
    [props, schema, _fetch, set_success, set_errors]
  );

  const _validate = useCallback(
    currentJson => {
      try {
        const obj = JSON.parse(currentJson);

        validationSchema
          .validate(obj, {
            abortEarly: false
          })
          .then(valid => {
            if (window._do_pwf_debug_log) console.log('JSON is valid');
            set_errors(null);
            set_success(null);
            set_schema(JSON.stringify(obj, null, 2));
          })
          .catch(err => {
            let _errors = {};
            if (err.name === 'ValidationError') {
              if (err.inner) {
                for (var key in err.inner) {
                  const field = err.inner[key];
                  const path = field.path;
                  const message = field.message;
                  _errors[path] = message;
                }
              }
            }
            set_schema(currentJson);
            set_success(null);
            set_errors(JSON.stringify(_errors));
          });
      } catch (e) {
        if (window._do_pwf_debug_log) console.log(e);
        set_schema(currentJson);
        set_success(null);
        set_errors(JSON.stringify(e.message));
      }
    },
    [set_errors, set_schema, set_success]
  );

  useEffect(() => {
    if (window._do_pwf_debug_log) console.log('fetching');
    _fetch();
  }, [_fetch]);

  if (window._do_pwf_debug_log) console.log(schema);
  if (window._do_pwf_debug_log) console.log(version);
  if (window._do_pwf_debug_log) console.log(errors);

  const entries = versions
    ? versions.map(elt => (
        <option key={elt.version_id} value={elt.version_id}>
          {elt.version_id}
        </option>
      ))
    : null;
  const versions_control = versions ? (
    <div className='select'>
      <select
        id='versions'
        value={version ? parseInt(version) : versions[0].version_id}
        onChange={e => {
          _fetch(e.target.value)
        }}
      >
        {entries}
      </select>
    </div>
  ) : null;

  return schema ? (
    <section className='section'>
      <div className='columns is-centered'>
        <div className='column is-full'>
          <div className='content'>
            <div className='content has-text-centered'>Load Selected Schema Version: {versions_control}</div>
          </div>
          <div className='content'>
            <AceEditor
              mode='json'
              theme='github'
              height='60vh'
              width='100%'
              value={schema}
              onChange={_validate}
              wrapEnabled={true}
              editorProps={{ $blockScrolling: Infinity }}
            />
          </div>
          <div className='content'>
            <div className='content has-text-centered'>
              <button className='button is-link' disabled={success || errors} onClick={_handle_submit}>
                Save Changes
              </button>
            </div>
          </div>
          <div className='content'>
            <div className='content has-text-centered has-text-danger'>{errors}</div>
          </div>
          <div className='content'>
            <div className='content has-text-centered has-text-success'>{success}</div>
          </div>
        </div>
      </div>
    </section>
  ) : null;
}
