import React, { useState, useCallback } from 'react';

import logo from '../../images/pwf_logo.jpg'; // with import

import { toast } from 'react-toastify';


import history from '../../utils/history';
import { rootpath } from '../../utils/rootpath';

export function PasswordResetWithToken(props) {
  const [password, set_password] = useState('');
  const [password2, set_password2] = useState('');

  const [error, set_error] = useState(null);

  const fn_reset = useCallback(
    async function(new_password) {
      const data = {
        token: props.token,
        password: new_password
      };

      const opts = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      };

      let rv = await props.networkfn(process.env.REACT_APP_API_SERVER + '/users/reset_password_with_token', opts);

      if (rv.ok) {
        toast.success('Password updated. Please log in with new password.');
        history.push({
          pathname: rootpath + `/`
        });
      } else {
        let errmsg = 'An unknown error occurred';

        if (rv && rv.returned_body && rv.returned_body.message) {
          errmsg = 'An error occurred: ' + rv.returned_body.message;
        } else if (rv.status_text) {
          errmsg = 'An error occurred: ' + rv.status_text;
        }

        set_error(errmsg);
      }
    },
    [props, set_error]
  );

  const handleSubmitClick = useCallback(
    e => {
      e.preventDefault();
      set_error(null);
      fn_reset(password);
    },
    [set_error, fn_reset, password]
  );

  const handleInputChange = useCallback(e => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let pass = password;
    let pass2 = password2;

    if (name === 'password') { set_password(value); pass=value; }
    if (name === 'password2') { set_password2(value); pass2=value; }

    if (pass.length < 8) {
      set_error('Password must be at least 8 characters long.');
    } else if (pass !== pass2) {
      set_error('Passwords must match.');
    } else {
      set_error(null);
    }

  }, [password, password2, set_password, set_password2, set_error]);

  // If you put other buttons in the form, they must have "type="button"" on them. Because... go read this:
  // https://stackoverflow.com/questions/932653/how-to-prevent-buttons-from-submitting-forms

  const could_resend = (error && (error.indexOf('invalid') >=0 || error.indexOf('expired') >=0)); 

  return (
    <section className='hero is-fullheight'>
      <div className='hero-body'>
        <div className='container has-text-centered'>
          <div className='column is-6 is-offset-3'>
            <h3 className='title has-text-grey'>Enter New Password</h3>
            <p className='subtitle has-text-grey'>Please enter your new password</p>
            <div className='box'>
              <figure className='avatar'>
                <img alt='Logo' width='128' height='128' src={logo} />
              </figure>
              <form>
                <div className='field'>
                  <div className='control'>
                    <input
                      name='password'
                      value={password}
                      onChange={handleInputChange}
                      className='input is-large'
                      type='password'
                      placeholder='New Password'
                      autoFocus={true}
                    />
                  </div>
                </div>

                <div className='field'>
                  <div className='control'>
                    <input
                      name='password2'
                      value={password2}
                      onChange={handleInputChange}
                      className='input is-large'
                      type='password'
                      placeholder='Confirm password'
                      autoFocus={false}
                    />
                  </div>
                </div>

                <div className='content'>
                  <div className='content has-text-centered has-text-danger'>{error}</div>
                </div>

                <button
                  className='button is-block is-info is-large is-fullwidth'
                  onClick={handleSubmitClick}
                  disabled={!!error}
                >
                  Reset my Password
                </button>
              </form>
            </div>
            <p className='has-text-grey'>
              <a className='app-link' href='/signup'>
                Sign Up
              </a>
              { could_resend ? <span>&nbsp; &nbsp; &nbsp; &nbsp;</span> : null } 
              { could_resend ? (
              <a className='app-link' href='/reset_password'>
                Create New Token
              </a>
              ) : null }
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
