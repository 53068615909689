import React, { useState, useCallback } from 'react';

import logo from '../../images/pwf_logo.jpg';

import 'font-awesome/css/font-awesome.min.css';

export function NavBar(props) {
  const [is_mobile_menu_expanded, set_mobile_menu_expanded] = useState(false);

  const handleToggleClick = useCallback(
    e => {
      e.preventDefault();
      set_mobile_menu_expanded(!is_mobile_menu_expanded);
    },
    [is_mobile_menu_expanded]
  );

  return (
    <nav className='navbar' role='navigation' aria-label='main navigation'>
      <div className='navbar-brand'>
        <a className='navbar-item brand-text app-link' href='/'>
          <img alt='logo' src={logo} className='pwf-logo' />
          <span>Pacific Whale Foundation</span>
        </a>
        <a
          role='button'
          className={`navbar-burger ${is_mobile_menu_expanded ? 'is-active' : ''}`}
          onClick={handleToggleClick}
          aria-label='menu'
          aria-expanded='false'
          href='/'
        >
          <span aria-hidden='true' />
          <span aria-hidden='true' />
          <span aria-hidden='true' />
        </a>
      </div>

      <div id='navbarDesktop' className='navbar-menu desktop is-hidden-touch'>
        <div className='navbar-start'>
          <a className='navbar-item app-link' href='/'>
            My Sightings
          </a>
          <a className='navbar-item app-link' href='/tracks'>
            My Tracks
          </a>
          <a className='navbar-item app-link' href='/map'>
            Map
          </a>
          <a className='navbar-item app-link' href='/tips'>
            Tips
          </a>
          <a className='navbar-item app-link' href='/about'>
            About
          </a>
          {props.user.is_admin ? (
            <a className='navbar-item app-link' href='/users'>
              <i className='fa fa-cog' aria-hidden='true'>
                &nbsp;
              </i>
              Users
            </a>
          ) : null}
          {props.user.is_admin ? (
            <a className='navbar-item app-link' href='/fields'>
              <i className='fa fa-cog' aria-hidden='true'>
                &nbsp;
              </i>
              Edit Fields
            </a>
          ) : null}
          {props.user.is_admin ? (
            <a className='navbar-item app-link' href='/admin_tips'>
              <i className='fa fa-cog' aria-hidden='true'>
                &nbsp;
              </i>
              Edit Tips
            </a>
          ) : null}
          {props.user.is_admin ? (
            <a className='navbar-item app-link' href='/admin_about'>
              <i className='fa fa-cog' aria-hidden='true'>
                &nbsp;
              </i>
              Edit About
            </a>
          ) : null}
        </div>
        <div className='navbar-end'>
          <a className='navbar-item app-link' href={'/useredit/' + props.user.uid}>
            <i>{props.user.username}</i>
          </a>
          <div className='navbar-item'>
            <div className='buttons'>
              <a className='button is-light app-link' href='/logout'>
                Log Out
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        id='navbarMobile'
        onClick={handleToggleClick}
        className={`navbar-menu mobile is-hidden-desktop ${is_mobile_menu_expanded ? 'is-active' : ''}`}
      >
        <div className='navbar-end'>
          <a className='navbar-item app-link' href='/'>
            Sightings{/*Home*/}
          </a>
          {/*<a className="navbar-item app-link" href='/sightings'>Sightings</a>*/}
          <a className='navbar-item app-link' href='/tracks'>
            Tracks
          </a>
          <a className='navbar-item app-link' href='/map'>
            Map
          </a>
          <a className='navbar-item app-link' href='/tips'>
            Tips
          </a>
          <a className='navbar-item app-link' href='/about'>
            About
          </a>
          {props.user.is_admin ? (
            <a className='navbar-item app-link' href='/users'>
              <i className='fa fa-cog' aria-hidden='true'>
                &nbsp;
              </i>
              Users
            </a>
          ) : null}
          <a className='navbar-item app-link' href={'/useredit/' + props.user.uid}>
            My Account
          </a>
          <a className='navbar-item app-link' href='/logout'>
            Log Out
          </a>
        </div>
      </div>
    </nav>
  );
}
