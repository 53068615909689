import history from './history';
import { rootpath } from './rootpath';

import 'font-awesome/css/font-awesome.min.css';

import WebFont from 'webfontloader';

import * as Yup from 'yup'; // for everything

Yup.addMethod(Yup.mixed, 'equalTo', function (ref, message) {
    // eslint-disable-next-line
    const msg = message || ('${path} should match ' + ref.path);
    return this.test('equalTo', msg, function (value) {
        let refValue = this.resolve(ref);
        return (!refValue && !value) || value === refValue;
    })
});

WebFont.load({
    google: {
        families: ['Open Sans:300,400,700', 'sans-serif']
    }
});

function setUpLinkHandlers() {

    function resolveRoute(ev) {
        let node = ev.currentTarget;
        if (!node)
            return;

        let href = node.getAttribute('href');

        if (!href) return;

        history.push({
            pathname: rootpath + ev.currentTarget.pathname
        });

        return ev.preventDefault();
    };

    let observer = new MutationObserver(records => {
        document.querySelectorAll('a.app-link').forEach(linkNode => {
            linkNode.removeEventListener('click', resolveRoute);
            linkNode.addEventListener('click', resolveRoute);
        });
    });

    observer.observe(document.documentElement, {
        childList: true,
        subtree: true
    });
}

setUpLinkHandlers();

