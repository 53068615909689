import React from 'react';
import { SightingMedia } from './sighting_media';
import { SightingTable } from './sighting_table';
import { SightingMap } from './sighting_map';

import { PhotoThumbnails } from './photo_thumbnails';

export class SightingViewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sighting: null
    };
    this._fetch = this._fetch.bind(this);
  }

  _fetch = async () => {
    const _schema = this.props.networkfn(process.env.REACT_APP_API_SERVER + '/schema', {});
    const _rsp = this.props.networkfn(process.env.REACT_APP_API_SERVER + '/sightings/id/' + this.props.sighting_id, {});

    // Await later so that tasks can complete in parallel.

    const rsp = await _rsp;
    const schema = await _schema;

    if (window._do_pwf_debug_log) console.log(rsp);
    if (window._do_pwf_debug_log) console.log(schema);

    if (rsp.ok) {
      this.setState({
        sighting: rsp.returned_body,
        schema: schema.returned_body.schema
      });
    } else {
      this.setState({ sighting: null, schema: null, error: rsp });
    }
  };

  componentDidMount() {
    this._fetch();
  }

  componentDidUpdate() {
    if (this.state.sighting && this.state.schema) {
      // Cache the schema definitions
      const schema_display_fields = {};

      for (var schema_field in this.state.schema.fields) {
        let field = this.state.schema.fields[schema_field];
        schema_display_fields[field.field_name] = field;
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    // or componentDidUpdate
    this._fetch();
  }

  render() {
    if (!this.state.sighting || !this.state.schema) return null;

    const sighting = this.state.sighting;
    const schema = this.state.schema;

    const loc_str = sighting.latitude + ', ' + sighting.longitude;

    // Cache the schema definitions
    const schema_display_fields = {};

    for (let schema_field in this.state.schema.fields) {
      let field = this.state.schema.fields[schema_field];
      schema_display_fields[field.field_name] = field;
    }

    const species_schema_definition = schema_display_fields ? schema_display_fields.species_code : null;

    if (!species_schema_definition) return null;

    const species_entry = species_schema_definition.allowed_values.find(av => av.key === sighting.species_code);

    if (!species_entry) return null;

    return (
      <section className='section'>
        <div className='columns is-centered'>
          <div className='column is-half'>
            <div className='card box' key={sighting.sighting_id}>
              <SightingMedia species_entry={species_entry} sighting_date_and_time_utc={sighting.sighting_date_and_time_utc} />
              <SightingTable schema={schema} sighting={sighting} />
              <div className='content'>
                <div className='content has-text-centered'>
                  {window.mobile_app_override ? null : (
                    <a href={'/edit_sighting/' + sighting.sighting_id} className='button is-large is-link app-link'>
                      Edit
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='column is-half'>
            <div className='card box'>
              <div className='content'>
                <p className='title is-4'>Location</p>
                <p className='subtitle is-6'>{loc_str}</p>
                <SightingMap
                  species_entry={species_entry}
                  latitude={sighting.latitude}
                  longitude={sighting.longitude}
                  height='400px'
                />
              </div>
            </div>

            <div className='card box'>
              <div className='content'>
                <p className='title is-4'>Images</p>
              </div>
              <div className='content'>
                <PhotoThumbnails photo_ids={this.state.sighting.sighting_photos} networkfn={this.props.networkfn} />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
