import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import loadImage from 'blueimp-load-image';
import loading_image from '../../images/loading.svg';

function _arrayBufferToBase64(buffer) {
  var binary = '';
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

async function load_and_parse(url) {
  if (window._do_pwf_debug_log) console.log(url);
  let result = await fetch(url, { credentials: 'include' });
  if (window._do_pwf_debug_log) console.log(result);
  if (window._do_pwf_debug_log) console.log(result.type);
  let ab = await result.arrayBuffer();
  if (window._do_pwf_debug_log) console.log(ab);

  var arr = new Uint8Array(ab).subarray(0, 4);
  var header = '';
  for (var i = 0; i < arr.length; i++) {
    header += arr[i].toString(16);
  }
  if (window._do_pwf_debug_log) console.log(header);

  let type = null;

  switch (header) {
    case '89504e47':
      type = 'image/png';
      break;
    case '47494638':
      type = 'image/gif';
      break;
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
      type = 'image/jpeg';
      break;
    default:
      type = 'unknown'; // Or you can use the blob.type as fallback
      break;
  }

  if (window._do_pwf_debug_log) console.log(type);

  const base64img = 'data:' + type + ';base64,' + _arrayBufferToBase64(ab);

  var scanner = new DataView(ab);
  var idx = 0;
  var orientation_value = 1; // Non-rotated is the default
  if (ab.length < 2 || scanner.getUint16(idx) !== 0xffd8) {
    // Not a JPEG
    return { img: base64img, orientation: orientation_value };
  }

  idx += 2;
  var maxBytes = scanner.byteLength;
  var littleEndian = false;

  while (idx < maxBytes - 2) {
    var uint16 = scanner.getUint16(idx, littleEndian);
    idx += 2;
    switch (uint16) {
      case 0xffe1: // Start of EXIF
        var endianNess = scanner.getUint16(idx + 8);
        // II (0x4949) Indicates Intel format - Little Endian
        // MM (0x4D4D) Indicates Motorola format - Big Endian
        if (endianNess === 0x4949) {
          littleEndian = true;
        }
        var exifLength = scanner.getUint16(idx, littleEndian);
        maxBytes = exifLength - idx;
        idx += 2;
        break;
      case 0x0112: // Orientation tag
        // Read the value, its 6 bytes further out
        // See page 102 at the following URL
        // http://www.kodak.com/global/plugins/acrobat/en/service/digCam/exifStandard2.pdf
        orientation_value = scanner.getUint16(idx + 6, littleEndian);
        maxBytes = 0; // Stop scanning
        break;
      default:
        break;
    }
  }

  return { img: base64img, orientation: orientation_value };
}

export function ImageViewer(props) {
  const imageCanvas = useRef(null);
  const [loading, set_loading] = useState(true);

  useEffect(() => {
    const ic_cur = imageCanvas.current;

    async function parse_orientation_and_load() {
      set_loading(true);

      let rv = await load_and_parse(props.src);

      loadImage(
        props.src,
        (img, data) => {
          if (window._do_pwf_debug_log) console.log(img);
          if (window._do_pwf_debug_log) console.log(JSON.stringify(img, null, 2));
          if (window._do_pwf_debug_log) console.log(data);
          if (window._do_pwf_debug_log) console.log(imageCanvas);

          const dnode = ReactDOM.findDOMNode(ic_cur);
          set_loading(false);

          if (img.type === 'error') return;
          
          dnode.appendChild(img);
        },
        {
          maxWidth: props.max_width,
          maxHeight: props.max_height,
          canvas: true,
          orientation: rv.orientation
        }
      );
    }

    parse_orientation_and_load();

    return () => {
      const dnode = ReactDOM.findDOMNode(ic_cur);
      const c = dnode.querySelector('canvas');
      if(c) dnode.removeChild(c);
    };
  }, [imageCanvas, props.src, props.max_width, props.max_height]);

  // http://www.mademyday.de/css-height-equals-width-with-pure-css.html
  return (
    <div className='aspect-box'>
      <div ref={imageCanvas} className='aspect-content'>{loading ? <img alt='loading' src={loading_image} /> : null}</div>
    </div>
  );
}
