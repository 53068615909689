import React, { useState, useCallback, useEffect } from 'react';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6

export function About(props) {

  const [about, set_about] = useState(null);

  const _fetch = useCallback(async () => {

    const _tips = props.networkfn(process.env.REACT_APP_API_SERVER + '/info/about', {});

    const returned_about = await _tips;

    if (returned_about.ok) {
      let obj = returned_about.returned_body;
      if (window._do_pwf_debug_log) console.log(obj);
      set_about(obj);
    } else {
      if (window._do_pwf_debug_log) console.log(returned_about);
      set_about(null);
    }
  }, [set_about, props]);

  
  useEffect(() => {
    if (window._do_pwf_debug_log) console.log('fetching');
    _fetch();
  }, [_fetch]);

  return (
    <section className='section'>
      <div className='columns is-centered'>
        <div className='column is-full'>
          <div className='content' />
          <div dangerouslySetInnerHTML={{__html: about}} />
        </div>
      </div>
    </section>
  );
}

export function EditAbout(props) {
  const [about, set_about] = useState(null);
  const [errors, set_errors] = useState(null);
  const [success, set_success] = useState(null);

  const _fetch = useCallback(async () => {

    const _about = props.networkfn(process.env.REACT_APP_API_SERVER + '/info/about', {});

    const returned_about = await _about;

    if (returned_about.ok) {
      let obj = returned_about.returned_body;
      if (window._do_pwf_debug_log) console.log(obj);
      set_about(obj);
    } else {
      if (window._do_pwf_debug_log) console.log(returned_about);
      set_about(null);
    }
  }, [set_about, props]);

  const _handle_submit = useCallback(
    async event => {
      event.preventDefault();

      if (window._do_pwf_debug_log) console.log(about);

      const about_to_submit = JSON.stringify( {text: about} );

      const opts = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: about_to_submit,
        credentials: 'include'
      };

      const rsp = await props.networkfn(process.env.REACT_APP_API_SERVER + '/info/about', opts);

      if (rsp.ok) {
        if (window._do_pwf_debug_log) console.log('Success');
        set_success(rsp.returned_body.message);
        set_errors(null);
        if (window._do_pwf_debug_log) console.log(rsp);
        _fetch();
      } else {
        if (window._do_pwf_debug_log) console.log('Error');
        if (window._do_pwf_debug_log) console.log(rsp);
        set_success(null);
        set_errors(JSON.stringify({ submitError: rsp.returned_body.message ? rsp.returned_body.message : rsp.status_text }));
      }

    },
    [props, about, _fetch, set_success, set_errors]
  );
  
  useEffect(() => {
    if (window._do_pwf_debug_log) console.log('fetching');
    _fetch();
  }, [_fetch]);

  return (
    <section className='section'>
      <div className='columns is-centered'>
        <div className='column is-full'>
          <div className='content'>
          <ReactQuill value={about}
                  onChange={v => { set_about(v); set_success(null); set_errors(null); }} />
          </div>
          <div className='content'>
            <div className='content has-text-centered'>
              <button className='button is-link' disabled={success || errors} onClick={_handle_submit}>
                Save Changes
              </button>
            </div>
          </div>
          <div className='content'>
            <div className='content has-text-centered has-text-danger'>{errors}</div>
          </div>
          <div className='content'>
            <div className='content has-text-centered has-text-success'>{success}</div>
          </div>
        </div>
      </div>
    </section>
  );
}
