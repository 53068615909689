import React from 'react';
import { pwfOpts, formatSightingDateTime } from '../../utils/_pfm-utils';

export function SightingMedia(props) {
  if (!props.species_entry) return null;

  const species_image_url = pwfOpts.imgurl + '/' + props.species_entry.image;

  const species_name = props.species_entry.name ? props.species_entry.name : props.species_entry.value;

  return (
    <div className="media">
      <div className="media-left">
        <figure className="image is-64x64">
          <img src={species_image_url} alt={species_name} />
        </figure>
      </div>
      <div className="media-content">
        <p className="title is-4">{species_name}</p>
        <p className="subtitle is-6" style={{marginBottom: '10px'}}>{formatSightingDateTime(props.sighting_date_and_time_utc)}</p>
      </div>
    </div>
  );
}
